import axios from 'axios';
import { User, UserCreateDto, UserUpdateDto } from '../models/User';
import { UserActivity } from '../models/UserActivity';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const USERS_URL = `${BACKEND_URL}/api/identity`;

class UserService {
	constructor() { }

	private getConfig(token: string) {
		return {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		};
	}

	async Create(token: string, data: UserCreateDto) {
		try {
			const response = await axios.post(`${USERS_URL}/create`, data, this.getConfig(token));
			return response.data;
		} catch (error) {
			console.error('Error sending contact form data:', error);
			throw error;
		}
	}

	async Update(token: string, data: UserUpdateDto) {
		console.log('[form] updating existing user with id:' + data.userId);
		try {
			const response = await axios.put(`${USERS_URL}/update`, data, this.getConfig(token));
			return response.data.values;
		} catch (error) {
			console.error('Error updating contact form data:', error);
			throw error;
		}
	}

	async UpdatePassword(token: string, data: any) {
		console.log('[form] updating password with id:' + data.userId);
		try {
			const response = await axios.patch(`${USERS_URL}/password`, data, this.getConfig(token));
			return response.data.values;
		} catch (error) {
			console.error('Error updating contact form data:', error);
			throw error;
		}
	}

	canDo(action: UserActivity, user: User): boolean {
		const type = user.type;
		if (type === undefined) {
			return false;
		}
		switch (action) {
			case UserActivity.Login:
				return true;
			case UserActivity.ChangeOwnPassword:
				return user.type == 255;
			case UserActivity.CreateUser:
				return user.type == 255;
			case UserActivity.CreateForm:
				return [150, 170, 200, 250, 255].includes(type!);
			case UserActivity.EditForm:
				return [150, 200, 250, 255].includes(type!);
			case UserActivity.CommentForm:
				return true;
			case UserActivity.ListSelfForms:
				return [150, 200, 250, 255].includes(type!);
			case UserActivity.ListTeamForms:
				return [170, 200, 250, 255].includes(type!);
			case UserActivity.ListAllForms:
				return [170, 250, 255].includes(type!);
			case UserActivity.ListSecondHandItems:
				return true;
			case UserActivity.EditSecondHandPrice:
				return [100, 250, 255].includes(type!);
		}
		return false;
	}

	getUserAbilities(user: User): UserActivity[] {
		const allActivities = [
			UserActivity.Login,
			UserActivity.ChangeOwnPassword,
			UserActivity.CreateUser,
			UserActivity.CreateForm,
			UserActivity.EditForm,
			UserActivity.CommentForm,
			UserActivity.ListSelfForms,
			UserActivity.ListTeamForms,
			UserActivity.ListAllForms,
			UserActivity.ListSecondHandItems,
			UserActivity.EditSecondHandPrice
		];

		return allActivities.filter((a) => this.canDo(a, user));
	}



}

export default new UserService();
