import { DateTime } from 'luxon';

export default class Comment {
	id?: string | undefined;
	createDate: DateTime | undefined;
	message: string | undefined;
	formId: string | undefined;
	userId: string | undefined;
	attachments: string[] | undefined;
}

