import axios from 'axios';
import Comment from '../models/Comment';
import FileService from './fileService';
import Tools from './tools';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const FORMS_URL = `${BACKEND_URL}/api/comment`;

class CommentService {
	constructor() { }

	private getConfig(token: string) {
		return {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		};
	}

	async uploadFiles(files: File[], commentId: number) {

	}


	async CreateComment(token: string | null | undefined, data: Comment, files: File[]): Promise<Comment> {
		console.log('[form] saving new comment', data);
		try {
			if (!token) {
				throw new Error('No token provided');
			}
			const response = await axios.post(`${FORMS_URL}/create`, data, this.getConfig(token));
			const comment: Comment = response.data;
			comment.attachments = [];
			if (files.length > 0 && comment && comment.id) {
				const randSuffix = Tools.generateRandomString(5);
				const ext = files[0].name.split('.').pop();
				const filename = `form_${data.formId}_attachment_${randSuffix}.${ext}`;
				for (let file of files) {
					const fn = await FileService.Upload(token, file, filename);
					comment.attachments.push(fn);
				}
			}
			return comment;

		} catch (error) {
			console.error('Error sending comment data:', error);
			throw error;
		}
	}

	async DeleteComment(token: string | null | undefined, commentId: string | undefined): Promise<void> {
		console.log('[form] deleting comment', commentId);
		try {
			if (!token) {
				throw new Error('No token provided');
			}
			await axios.delete(`${FORMS_URL}/delete/${commentId}`, this.getConfig(token));

		} catch (error) {
			console.error('Error sending comment data:', error);
			throw error;
		}
	}

	async UpdateComment(token: string | null | undefined, commentId: string | undefined, message: string): Promise<Comment | undefined> {
		console.log('[form] updating comment', commentId);
		try {
			if (!token) {
				throw new Error('No token provided');
			}
			const comment = await axios.patch(`${FORMS_URL}/update/${commentId}`, { message: message }, this.getConfig(token));
			console.log('[form] updated comment', comment.data);
			return comment.data;

		} catch (error) {
			console.error('Error sending comment data:', error);
			throw error;
		}
	}

	async GetComments(token: string | null | undefined, orderId) {
		try {
			if (!token) {
				throw new Error('No token provided');
			}
			const response = await axios.get(`${FORMS_URL}/orderComments/${orderId}`, this.getConfig(token));
			const result: Comment[] = response.data;
			return result;
		} catch (error) {
			console.error('Error getting comment data:', error);
			throw error;
		}
	}

}

export default new CommentService();