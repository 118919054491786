import React, { ReactNode, useEffect } from 'react';
import { useDanteStore } from '../store/store';
import { useState } from 'react';
import { DocumentDuplicateIcon, UsersIcon } from '@heroicons/react/24/outline';
import Spinner from '../components/spinner';
import { useLocation } from 'wouter';
import logo from '../assets/dante_logo.png';
import Tools from '../services/tools';
import SalesBranch from '../models/SalesBranch';
import UserType from '../models/UserType';
import { Button, Modal } from 'flowbite-react';
import { IoIosLogOut } from "react-icons/io";
import { CiSettings } from "react-icons/ci";
import { HiOutlineExclamationCircle } from 'react-icons/hi';
interface InsideLayoutProps {
	children: ReactNode;
}

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const InsideLayout = (props: InsideLayoutProps) => {
	const { children } = props;
	const [location, navigate] = useLocation();
	const { auth, loading } = useDanteStore(state => ({ auth: state.auth, loading: !state.allLoaded }));
	const branches: SalesBranch[] = useDanteStore((state) => state.branches);
	const [userData, setUserData] = useState({ name: '', email: '', imageUrl: '', branch: '', title: '' });
	const [showLogoutAlert, setShowLogoutAlert] = useState(false);

	const navigation = [
		//{ name: 'Ana Sayfa', href: '/', icon: HomeIcon },
		{ name: 'Kullanıcılar', href: '/users', icon: UsersIcon },
		{ name: 'Görüşmeler', href: '/forms', icon: DocumentDuplicateIcon },
		{ name: 'Yeni Görüşme', href: '/forms/new', icon: DocumentDuplicateIcon },
	];

	const checkActive = item => {
		return location == item.href;
	};

	useEffect(() => {
		if (auth?.user) {
			const user = auth.user!;
			const title = UserType.filter(t => t.value == user.type)[0].label;

			setUserData({
				name: user.fullName!,
				email: user.email!,
				imageUrl: Tools.getAvatar(user.fullName),
				branch: branches.find(b => b.id == user.branchId)?.name ?? 'Türkiye',
				title: title,
			});
		} else {
			setUserData({ name: '', email: '', imageUrl: '', branch: '', title: '' });
		}
	}, [auth, branches]);

	return (
		<>
			<div className="flex flex-col w-screen h-screen">
				{loading && <Spinner />}

				<div className='absolute flex flex-col w-48 h-full bg-black'>
					<div className='relative h-24 p-4 bg-gradient-to-b from-blue-300 to-black'>
						<img
							className="logo"
							src={logo}
							alt="Dante"
							onClick={() => {
								navigate('/');
							}}
						/>
					</div>
					<div className='flex flex-1 p-4'>

						<nav className="flex flex-col flex-1">
							<ul role="list" className="flex flex-col flex-1 gap-y-7">
								<li>
									<ul role="list" className="-mx-2 space-y-1">
										{navigation.map(item => (
											<li key={item.name}>
												<a
													onClick={() => navigate(item.href)}
													className={classNames(
														checkActive(item) ? 'bg-gray-700 text-white' : 'text-gray-400 cursor-pointer hover:text-white hover:bg-gray-800',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}
												>
													<item.icon className="w-6 h-6 shrink-0" aria-hidden="true" />
													{item.name}
												</a>
											</li>
										))}
									</ul>
								</li>
							</ul>
						</nav>
					</div>
					<div className='flex flex-col px-2 py-2 h-36 bg-gradient-to-b from-black to-slate-600'>
						<ul className='border-2 border-green-500 rounded-xl bg-gradient-to-b from-black to-slate-700'>
							<li>
								<div className="flex items-center px-2 py-2 text-sm font-semibold text-white select-none gap-x-2">
									<img className="w-8 h-8 bg-gray-800 rounded-full" src={userData.imageUrl} alt="" />
									<span aria-hidden="true">{userData.name}</span>
								</div>
							</li>
							<li className='p-0 m-0 mb-1'>
								<div className='h-4 text-center text-white text-tiny'>{userData.title}</div>
								<div className='h-4 text-center text-green-600 text-tiny'>{userData.branch}</div>
							</li>
						</ul>
						<div className='flex mt-1 -mb-3'>
							<Button color="gray" className='w-1/2 opacity-10' disabled outline><CiSettings /></Button>
							<Button color="gray" className='w-1/2 opacity-80' outline onClick={() => setShowLogoutAlert(true)}><IoIosLogOut /></Button>
						</div>
					</div>

				</div>

				<main className="h-full py-4 mr-4 overflow-y-auto pl-52">
					{children}
				</main>
			</div>
			<Modal show={showLogoutAlert} size="md" onClose={() => setShowLogoutAlert(false)} popup>
				<Modal.Header />
				<Modal.Body>
					<div className="text-center">
						<HiOutlineExclamationCircle className="mx-auto mb-4 text-gray-400 h-14 w-14 dark:text-gray-200" />
						<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
							Çıkış yapıyorsunuz? Kaydetmediğiniz bilgiler silinecektir.
						</h3>
						<div className="flex justify-center gap-4">
							<Button color="failure" onClick={() => navigate('/logout')}>
								Tamam, çık
							</Button>
							<Button color="gray" onClick={() => setShowLogoutAlert(false)}>
								Hayır, çıkma
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default InsideLayout;
