import React, { useState, useEffect, useRef } from 'react';
import './phoneInput.css';

interface PhoneInputProps {
	value: string | undefined;
  name: string;
  showCountry?: boolean;
  onPhoneChange: (phone: string) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ value, name, showCountry, onPhoneChange }) => {
	const [firstPart, setFirstPart] = useState<string>('');
	const [secondPart, setSecondPart] = useState<string>('');
	const [thirdPart, setThirdPart] = useState<string>('');

	const secondPartRef = useRef<HTMLInputElement>(null);
	const thirdPartRef = useRef<HTMLInputElement>(null);
	
	useEffect(() => {
		if (value && value.length === 10) {
			setFirstPart(value.substring(0, 3));
			setSecondPart(value.substring(3, 6));
			setThirdPart(value.substring(6, 10));
		}
	}, [value]);

	const handleChange = (
		value: string,
		setter: React.Dispatch<React.SetStateAction<string>>,
		maxLength: number,
		nextRef?: React.RefObject<HTMLInputElement>
	) => {
		
		if (value.length <= maxLength) {
			setter(value);
			if (value.length === maxLength && nextRef?.current) {
				nextRef.current.focus();
			}
		} 
	};

	const handleBlur = () => {
		const phoneNumber = `${firstPart}${secondPart}${thirdPart}`;
		if (phoneNumber.length === 10) {
			onPhoneChange(phoneNumber);
		}
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const isNumberKey = /^[0-9]+$/.test(e.key);
		const phoneNumber = `${firstPart}${secondPart}${thirdPart}`;
		if (!isNumberKey || (e.key === 'Enter' && phoneNumber.length !== 10)) {
			e.preventDefault();
		}
		if (phoneNumber.length === 10) {
			console.log('phone recorded:', phoneNumber);
			onPhoneChange(phoneNumber);
			thirdPartRef?.current?.blur();
		}
	};

	return (
		<div className="phone-input-container">
			<label>
				{showCountry && '+90 (0)'}
			</label>
			<input
				value={firstPart.trim()}
				onChange={(e) => handleChange(e.target.value, setFirstPart, 3, secondPartRef)}
				onBlur={handleBlur}
				onKeyUp={handleKeyPress}
				maxLength={3}
				type='number'
			/>
			<label>-</label>
			<input
				ref={secondPartRef}
				value={secondPart.trim()}
				onChange={(e) => handleChange(e.target.value, setSecondPart, 3, thirdPartRef)}
				onBlur={handleBlur}
				onKeyUp={handleKeyPress}
				maxLength={3}
				type='number'
			/>
			<label>-</label>
			<input
				ref={thirdPartRef}
				value={thirdPart.trim()}
				onChange={(e) => handleChange(e.target.value, setThirdPart, 4, undefined)}
				onBlur={handleBlur}
				onKeyUp={handleKeyPress}
				maxLength={4}
				type='number'
			/>
			<input type="hidden" name={name} value={`${firstPart}${secondPart}${thirdPart}`} />
		</div>
	);
};

export default PhoneInput;
