import React, { useEffect } from 'react';

import MainPage from './pages/mainPage';
import { useDanteStore } from './store/store';
import './App.css';
import 'react-slideshow-image/dist/styles.css';
import { AuthResult } from './models/AuthResult';
import { Route, Switch, Router } from 'wouter';
import Logout from './pages/logout';
import InsideLayout from './layouts/inside.layout';
import HomePageInlet from './inlets/home.inlet';
import UsersPageInlet from './inlets/users.inlet';
import UserEditInlet from './inlets/user.edit.inlet';
import UserNewInlet from './inlets/user.new.inlet';
import UserChangePassInlet from './inlets/user.changepass';
import OrderInlet from './inlets/order.inlet';
import FormCreateInlet from './inlets/form.new';
import FormEditInlet from './inlets/form.edit';
import ListFormsInlet from './inlets/forms.list';
import userService from './services/userService';

interface AppProps {
	auth?: AuthResult;
}

const App: React.FC<AppProps> = (props: AppProps) => {
	const rootState = useDanteStore((state) => state);
	const isAuthenticated = useDanteStore((state) => state.auth?.isAuthenticated) || props.auth?.isAuthenticated;
	const user = useDanteStore((state) => state.auth?.user);
	if (user) {
		const abilities = userService.getUserAbilities(user);
		console.log(`[user] abilities: ${abilities}`);
	}

	useEffect(() => {
		rootState.fetchRegistry();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			if (props.auth?.isAuthenticated) {
				await rootState.setAuth(props.auth);
			}
			if (rootState.brands.length == 0) {
				await rootState.fetchBrands();
			}
			if (rootState.colors.length == 0) {
				await rootState.fetchColors();
			}
			if (rootState.clientTypes.length == 0) {
				await rootState.fetchClientTypes();
			}
			if (rootState.truckGroups.length == 0) {
				await rootState.fetchTruckGroups();
			}
			if (rootState.truckVariants.length == 0) {
				await rootState.fetchTruckVariants();
			}
			if (rootState.branches.length == 0) {
				await rootState.fetchBranches();
			}
			if (rootState.cities.length == 0) {
				await rootState.fetchCities();
			}
			if (isAuthenticated) {
				await Promise.all([
					rootState.fetchTrucks(),
					rootState.fetchUsers(),
					rootState.fetchForms(),
				]);
			}
		};
		fetchData();
	}, [isAuthenticated]);

	const MyRoute = () => (
		<Router>
			<InsideLayout>
				<Switch>
					<Route path="/" component={HomePageInlet} />
					<Route path="/users/new" component={UserNewInlet} />
					<Route path="/users/pass/:id?" component={UserChangePassInlet} />
					<Route path="/users" component={UsersPageInlet} />
					<Route path="/users/:id?" component={UserEditInlet} />
					<Route path="/forms" component={ListFormsInlet} />
					<Route path="/forms/new" component={FormCreateInlet} />
					<Route path="/forms/:id?" component={FormEditInlet} />
					<Route path="/order/:id?" component={OrderInlet} />
					<Route path="/logout" component={Logout} />
				</Switch>
			</InsideLayout>
		</Router>
	);

	return (
		<>
			{!isAuthenticated ? <MainPage /> : <MyRoute />}
		</>
	);
};

export default App;