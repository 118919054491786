import React from 'react';
import { District } from '../models/Geo';

interface DistrictSelectorProps {
    districts: District[];
    onSelect: (value: District | undefined | null) => void;
    selectedId?: number;
    className: string;
}

const DistrictSelector = (props: DistrictSelectorProps) => {
	const { onSelect, selectedId, className } = props;
	const districts = props.districts.sort((a, b) => a.name.localeCompare(b.name));
  

	const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedId = e.target.value;
		if (selectedId) {
			const district = districts.find((x) => x.id == parseInt(selectedId));
			onSelect(district);
		} else {
			onSelect(null);
		}
	};

	return (
		<select value={selectedId || ''} onChange={handleSelect} className={className}>
			<option value="0">???</option>
			{districts.map((district) => (
				<option key={district.id} value={district.id}>
					{district.name}
				</option>
			))}
		</select>
	);
};

export default DistrictSelector;
