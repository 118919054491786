import React from 'react';
import { City } from '../models/Geo';
import { useDanteStore } from '../store/store';

interface CitySelectorProps {
    onSelect: (value: City | undefined | null) => void;
    className: string;
    selectedId?: number;
}

const CitySelector = (props: CitySelectorProps) => {
	const { onSelect, selectedId, className } = props;
	const cities = useDanteStore((state) => state.cities).sort((a, b) => a.name.localeCompare(b.name));
  
	const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedId = parseInt(e.target.value);
		if (selectedId) {
			const city = cities.find((x) => x.id == selectedId);
			onSelect(city);
		} else {
			onSelect(null);
		}
	};

	return (
		<select value={selectedId || ''} onChange={handleSelect} onSelect={handleSelect} className={className}>
			<option value="">???</option>
			{cities.map((city) => (
				<option key={city.id} value={city.id}>
					{city.name}
				</option>
			))}
		</select>
	);
};

export default CitySelector;
