/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import Comment from '../models/Comment';
import { useDanteStore } from '../store/store';
import { DateTime } from 'luxon';
import Tools from '../services/tools';
import { chat_bubble, clipboard, deleteIcon, editIcon } from '../assets';
import './CommentComponent.css';

enum CommentAction {
	Read,
	New,
	Edit
}

interface CommentProps {
	comment?: Comment;
	action: CommentAction;
	formId?: string | undefined;
	createComment?: (token: string, comment: Comment, files: File[]) => Promise<Comment | undefined>;
	deleteComment?: (token: string, commentId: string | undefined) => Promise<void>;
	editComment?: (token: string, commentId: string | undefined) => Promise<void>;
}

const CommentComponent = (props: CommentProps) => {
	const [isDeleted, setIsDeleted] = useState(false);
	const { action, comment, formId } = props;
	const token = useDanteStore((state) => state.auth?.accessToken);
	const myUser = useDanteStore((state) => state.auth?.user);
	const commentUser = useDanteStore((state) => state.users.find((u) => u.userId === comment?.userId));
	const [message, setMessage] = useState(comment?.message);

	const fileInput = useRef<HTMLInputElement>(null);
	const [files, setFiles] = useState([]);

	const newMessageBoxRef = useRef<HTMLTextAreaElement>(null);

	const ACTION_READ = action == CommentAction.Read;
	const ACTION_NEW = action == CommentAction.New;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (e.target.value || e.target.value == '') {
			setMessage(e.target.value.trim());
		}
	};

	const handleFileChange = (event) => {
		// Setting the selected files to state
		setFiles(event.target.files);
	};

	// const AddAttachment = () => {
	// };

	const AddComment = async (): Promise<Comment | undefined> => {
		if (!message || message?.trim().length == 0) return;
		const newComment = new Comment();
		newComment.createDate = DateTime.now();
		newComment.userId = myUser?.userId;
		newComment.message = message;
		newComment.formId = formId;
		setMessage('');
		if (newMessageBoxRef.current) {
			newMessageBoxRef.current.value = '';
		}
		const result = await props.createComment?.(token!, newComment, files);
		return result;
	};

	const deleteComment = async (commentId) => {
		if (confirm('Yorumu silmek istediğinize emin misiniz?')) {
			if (props.deleteComment && token && commentId) {
				await props.deleteComment(token!, commentId);
				setIsDeleted(true);
			}
		}
	};

	const editComment = async (commentId) => {
		if (props.editComment && token && commentId) {
			await props.editComment(token!, commentId);
		}
	};

	// const uploadFiles = async (files, commentId) => {
	// 	console.log('Uploading files for comment ID:', commentId);
	// 	// Here you would have your logic to upload files to the backend
	// 	// This could be done using FormData and making a POST request
	// };


	// const handleFileUpload = async () => {
	// 	// Send the file to the server here
	// };


	const getClassName = (isDeleted: boolean) => {
		return isDeleted ? 'fade-out full-comment' : 'full-comment';
	};

	return (
		<>
			<li key={comment?.id} className={getClassName(isDeleted)}>
				{
					ACTION_READ && (
						<>
							<div className='flex flex-col justify-center w-40 p-2 m-0.5 mr-2 bg-gray-300 rounded-md bg-gradient-to-b from-gray-200 to-gray-300'>
								<div className='flex justify-center w-full h-6 mt-1'>
									<img src={Tools.getAvatar(commentUser?.fullName)} className="self-center flex-none w-6 h-6 rounded-full bg-gray-50" />
								</div>
								<div className='flex justify-center w-full h-6 mt-2'>
									<b>{commentUser?.fullName}</b>
								</div>
								<div className='w-full text-center cursor-pointer'>
									<span className='text-tiny'>{Tools.displayDate(comment?.createDate)}</span>
								</div>
							</div>
							<div className="flex-1 p-3 bg-white rounded-md cursor-pointer parent-div ring-1 ring-inset ring-gray-200">
								<div className='relative float-right child-div'>
									<button onClick={() => editComment(comment?.id)}><img src={editIcon} className='w-6' /></button>
									<button onClick={() => deleteComment(comment?.id)}><img src={deleteIcon} className='w-6' /></button>
								</div>
								<p className="text-sm leading-6 text-gray-500">{message}</p>
							</div>
						</>
					)
				}
				{
					ACTION_NEW && (
						<>
							<div className='flex flex-col justify-center w-40 p-2 m-0.5 mr-2 bg-blue-50 rounded-md'>
							</div>
							<div className="flex-1 p-3 bg-white rounded-md ring-1 ring-inset ring-gray-200">
								<textarea ref={newMessageBoxRef}
									placeholder='Yorumunuzu buraya yazın...'
									rows={3}
									className='w-full h-20 text-sm leading-6 text-gray-500 border-none resize-none'
									onChange={handleChange}>{message}</textarea>
							</div>
						</>
					)
				}

			</li>
			{
				ACTION_NEW && (
					<li>
						<div className='flex flex-row justify-end w-full'>
							<button disabled onClick={() => fileInput.current?.click()} className='px-3 py-1 mr-1 text-white bg-green-500 rounded-md opacity-50 disabled:'>
								<img src={clipboard} className='float-left w-5 mr-1' />Belge ekle
							</button>
							<button onClick={AddComment} className='px-3 py-1 text-white bg-green-500 rounded-md'>
								<img src={chat_bubble} className='float-left w-5 mr-1' />Yorum ekle
							</button>
							<input type="file" multiple ref={fileInput} style={{ display: 'none' }} onChange={handleFileChange} />
						</div>
					</li>
				)
			}
		</>
	);
};


export { CommentAction, CommentComponent };


