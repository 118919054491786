import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import TimeAgo from 'javascript-time-ago';
import tr from 'javascript-time-ago/locale/tr';
import { externalLogin } from './store/store';
import { AuthResult } from './models/AuthResult';

console.log('INIT');
console.log(`BackendURL: ${process.env.REACT_APP_BACKEND_URL}`);

TimeAgo.addDefaultLocale(tr);
const AUTO_LOGIN = false;
window.initApp = async function () {
	async function getAuthResult(): Promise<AuthResult> {
		let authResult = {} as AuthResult;
		if (AUTO_LOGIN) {
			//authResult = await externalLogin('kutay.yetkin@danteticaret.com', 'xxxxxx');
			authResult = await externalLogin('umut@celenli.com', 'xxxxxx');
			//const authResult = await externalLogin('mum@essil.com', 'xxxxxx'); 
			//authResult = await externalLogin('sson@destek.com', 'xxxxxx');
			//authResult = await externalLogin('umut@celenli.com', 'xxxxxx');
		}
		return authResult;
	}
	const authResult = await getAuthResult();
	const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
	root.render(<App auth={authResult} />);
};