import React, { useEffect } from 'react';
import { User } from '../models/User';
import { useDanteStore } from '../store/store';
import { Link } from 'wouter';
import SalesBranch from '../models/SalesBranch';

const UsersPageInlet: React.FC = () => {

	const user = useDanteStore((state) => state.auth?.user);
	const users: User[] = useDanteStore((state) => state.users).sort((a, b) => a.fullName!.localeCompare(b.fullName!));
	const branches: SalesBranch[] = useDanteStore((state) => state.branches);

	const [CAN_CREATE_USER, setCAN_CREATE_USER] = React.useState(false);
	const [CAN_EDIT_USER, setCAN_EDIT_USER] = React.useState(false);

	const getBranchName = (branchId: number | undefined) => {
		const branch = branches.find((b) => b.id === branchId);
		return branch?.name ?? 'Türkiye';
	};

	const getTitle = (type: number | undefined) => {
		switch (type) {
			case 255: return 'Admin';
			case 250: return 'Yönetim';
			case 200: return 'Satış Müdürü';
			case 170: return 'Satış Sonrası Destek';
			case 150: return 'Satış Mümessili';
			case 100: return 'İkinci El uzmanı';
			default: return 'Bilinmiyor';
		}
	};

	useEffect(() => {
		if (user) {
			console.log(user);
			if (user?.type! >= 200) {
				console.log('user can create user');
				setCAN_CREATE_USER(true);
				setCAN_EDIT_USER(true);
			} else {
				console.log('user cannot create user');
			}
		}
	}, [user]);

	return (
		<div>
			{/* <div className='text-white rounded-t-lg bg-gradient-to-b from-blue-800 to-blue-900'>
				<h1 className='p-2 text-2xl font-bold text-center'>Kullanıcılar</h1>
			</div>
			<div className='flex flex-row p-5 text-white rounded-b-lg bg-gradient-to-b from-white to-blue-100'>
				<div className='flex-1'>
					.		
				</div>
				<div className='w-[150px]'>
					
					{ 
						CAN_CREATE_USER && <Link href="/users/new" 
							className="block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
							Kullanıcı ekle
						</Link>
					}
				</div>
			</div> */}

			<div className="flex flex-col flex-1 p-2 mb-2 overflow-x-hidden bg-gray-100 border-2 border-gray-200 shadow rounded-xl">

				<h1 className="relative p-4 px-4 text-xl text-center border-2 border-green-200 rounded-b -top-3 bg-gradient-to-b from-white to-green-200">
					Kullanıcılar
					{
						CAN_CREATE_USER && <Link href="/users/new"
							className="float-right px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
							Kullanıcı ekle
						</Link>
					}
				</h1>
				<table className="w-full text-left">
					<thead className="bg-gray-200">
						<tr>
							<th scope="col" className="relative pl-3 text-sm font-semibold text-left text-gray-900 isolate">
								İsim
								<div className="absolute inset-y-0 w-screen border-b right-full -z-10 border-b-gray-200" />
								<div className="absolute inset-y-0 left-0 w-screen border-b -z-10 border-b-gray-200" />
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
							>
								Rol
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
							>
								Email
							</th>
							<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
								Şube
							</th>
							<th scope="col" className="relative py-3.5 pl-3">
								<span className="sr-only">Edit</span>
							</th>
						</tr>
					</thead>
					<tbody className="overflow-x-hidden bg-white divide-y divide-gray-300">
						{users.map((person) => (
							<tr key={person.email} className='even:bg-gray-50'>
								<td className="p-3 text-sm font-medium text-gray-900">{person.fullName}</td>
								<td className="p-3 text-sm text-gray-500 sm:table-cell">{getTitle(person.type)}</td>
								<td className="p-3 text-sm text-gray-500 md:table-cell">{person.email}</td>
								<td className="p-3 text-sm font-medium">{getBranchName(person.branchId)}</td>
								<td className="p-3 text-sm font-medium text-right">
									{
										CAN_EDIT_USER && <Link href={`/users/${person.userId}`} className="text-indigo-600 hover:text-indigo-900">
											Düzenle
										</Link>
									}

								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};


export default UsersPageInlet;
