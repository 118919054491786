import React from 'react';
import { useDanteStore } from '../store/store';
import { User } from '../models/User';
import { navigate } from 'wouter/use-browser-location';
import userService from '../services/userService';
import './userform.css';
import { useParams } from 'wouter';
import './userform.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'flowbite-react';

const UserChangePassInlet: React.FC = () => {

	const params = useParams();
	const userId = params?.id ?? undefined;
	const rootState = useDanteStore((state) => state);

	const token = useDanteStore((state) => state.auth?.accessToken);
	const users: User[] = useDanteStore((state) => state.users);
	const user = users.find((u) => u.userId === userId);
	const [saving, setSaving] = React.useState(false);

	const formik = useFormik({
		initialValues: {
			userId: user?.userId,
			newpassword: '',
			newpassword2: '',
		},
		onSubmit: async (values) => {
			setSaving(true);
			console.log('onsubmit', values);

			if (values.newpassword !== values.newpassword2) {
				console.error('Passwords do not match');
				// Handle the error or display a message here.
			} else {
				var result = await userService.UpdatePassword(token!, values);
				console.log('result', result);
				rootState.fetchUsers();
				navigate('/users');
			}
			setSaving(false);
		},
		validationSchema: Yup.object({
			newpassword: Yup.string()
				.min(6, 'En az 6 karakter girmelisiniz')
				.required('Yeni şifre giriniz'),
			newpassword2: Yup.string()
				.min(6, 'En az 6 karakter girmelisiniz')
				.required('Yeni şifreyi tekrar giriniz')
				.oneOf([Yup.ref('newpassword')], 'Şifreler eşleşmiyor'),
		}),
	});

	return (

		<div className="h-full">

			<div className='p-4 bg-blue-300 rounded-sm'> Kullanıcı Şifre Yenileme</div>

			<div className='flex flex-row mb-12'>

				<form onSubmit={formik.handleSubmit} className='w-full'>
					<div className='w-full p-5 bg-gray-50'>
						<div className='w-full'>

							<div className='p-4 bg-gray-200 rounded-lg'>
								<label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
									İsim
								</label>
								<div className="mt-2">
									{user && user.fullName}
								</div>

							</div>

							<div className='p-4 mt-1 bg-gray-200 rounded-lg'>
								<div className='error'>
									{formik.errors.newpassword && formik.touched.newpassword && formik.errors.newpassword}
								</div>
								<label htmlFor="newpassword" className="block text-sm font-medium leading-6 text-gray-900">
									Yeni Şifre
								</label>
								<div className="mt-2">
									<input
										id="newpassword"
										name="newpassword"
										type="password"
										value={formik.values.newpassword}
										autoComplete="off"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="emailInput"
									/>
								</div>
							</div>

							<div className='p-4 mt-1 bg-gray-200 rounded-lg'>
								<div className='error'>
									{formik.errors.newpassword2 && formik.touched.newpassword2 && formik.errors.newpassword2}
								</div>
								<label htmlFor="newpassword2" className="block text-sm font-medium leading-6 text-gray-900">
									Yeni Şifre (Tekrar)
								</label>
								<div className="mt-2">
									<input
										id="newpassword2"
										name="newpassword2"
										type="password"
										value={formik.values.newpassword2}
										autoComplete="off"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="emailInput"
									/>
								</div>
							</div>


						</div>

						<div className="w-full p-2 ">
							<div className="flex items-center justify-end mt-6 gap-x-3">
								<Button color="green" type="submit" className='float-right mx-2'>
									{saving && <span className="absolute inline-flex w-8 h-8 ml-6 rounded-full opacity-75 animate-ping bg-sky-400"></span>}
									Kaydet
								</Button>
								<Button color="red" className='float-right' onClick={() => navigate('/users')}>Kapat</Button>
							</div>
						</div>

					</div>
				</form>

			</div>

		</div>
	);
};



export default UserChangePassInlet;

