import { useDanteStore } from '../store/store';
import { useEffect } from 'react';
import React from 'react';
import Tools from '../services/tools';
// import { useLocation, useNavigate } from 'react-router-dom';
import { useLocation } from 'wouter';
import { Form } from '../models/Form';
import { Truck } from '../models/Truck';
import { City } from '../models';

const ListFormsInlet: React.FC = () => {

	const user = useDanteStore((state) => state.auth?.user);
	const trucks: Truck[] = useDanteStore((state) => state.trucks);
	const forms: Form[] = useDanteStore((state) => state.forms);
	const users = useDanteStore((state) => state.users);
	const cities = useDanteStore((state) => state.cities);
	const [, setLocation] = useLocation();
	const { loading } = useDanteStore(state => ({ loading: !state.allLoaded }));

	const getDates = (forms: Form[]) => {
		console.log('[form] setting dates', forms);
		const _dates: string[] = [];
		for (let i = 0; i < forms.length; i++) {
			const form = forms[i];
			const date = Tools.FormatDate(form.createDate?.toString());
			if (!_dates.includes(date)) {
				_dates.push(date);
			}
		}
		//setDates(_dates.sort());
		console.log('dates', _dates);
	};

	const getCity = (id: number | undefined): City | undefined => {
		if (!id || cities.length == 0) return undefined;
		const city = cities.filter((x) => x.id == id)[0];
		return city;
	};

	const getTruckName = (id: number | undefined): string => {
		if (!id || trucks.length == 0) return '';
		const model = trucks.filter((x) => x.id == id)[0];
		const chunks = model.model.split('(');
		return chunks[0];
	};

	const getTown = (form: Form): string => {
		const city = getCity(form.cityId);
		const district = city?.districts.filter((x) => x.id == form.districtId)[0];
		return `${city?.name ?? ''} / ${district?.name ?? ''}`;
	};

	const getSalesRep = (id: string | undefined): string => {
		if (!id || users.length == 0) return '';
		var rep = users.filter((x) => x.userId == id)[0];
		return rep.fullName ?? '';
	};

	const goView = (id: number | undefined) => {
		if (id) {
			setLocation(`/order/${id}`);
		}
	};

	useEffect(() => {
		if (forms.length == 0) return;
		console.log(`[f] we have ${forms.length} forms`);
		const fetchData = async () => {
			const list = forms.filter((x) => x.salesRep?.userId == user!.userId);
			getDates(list);
		};
		fetchData();
	}, [forms]);

	return (
		<>
			<div className="flex flex-col flex-1 p-2 mb-2 overflow-x-hidden bg-gray-100 border-2 border-gray-200 shadow rounded-xl">
				<h1 className="relative p-4 px-4 text-xl text-center border-2 border-blue-200 rounded-b -top-3 bg-gradient-to-b from-white to-blue-200">
					Görüşmeler
				</h1>
				<table className="min-w-full overflow-x-hidden border-2 border-t-slate-200">
					<thead className='bg-gray-200'>
						<tr>
							<th className="w-2/12 px-2 py-3 text-sm font-medium text-left text-gray-800">Mümessil</th>
							<th className="w-2/12 px-2 py-3 text-sm font-medium text-left text-gray-800 ">Tarih</th>
							<th className="w-2/12 px-2 py-3 text-sm font-medium text-left text-gray-800 ">Müşteri</th>
							<th className="w-4/12 px-2 py-3 text-sm font-medium text-left text-gray-800">İl/İlçe</th>
							<th className="w-4/12 px-2 py-3 text-sm font-medium text-left text-gray-800 whitespace-nowrap">Model</th>
						</tr>
					</thead>
					<tbody className="overflow-x-hidden bg-white divide-y divide-gray-300">
						{forms?.length == 0 && !loading &&
							<tr><td colSpan={4} className='p-4'>Kayıt yok.</td></tr>
						}
						{forms.map((form) => (
							<tr id={`key_${form.formId}`} key={`fkey_${form.formId}`} className="cursor-pointer hover:bg-green-100 even:bg-gray-50" onClick={() => { goView(form.formId); }}>
								<td className="w-2/12 px-2 py-3 text-sm font-medium text-gray-500">{getSalesRep(form.salesRepUserId)}</td>
								<td className="w-2/12 px-2 py-3 text-sm font-medium text-gray-900 ">{Tools.formatDate(form.createDate)}</td>
								<td className="w-2/12 px-2 py-3 text-sm font-medium text-gray-900 ">{form.fullName}</td>
								<td className="w-4/12 px-2 py-3 text-sm font-medium text-gray-500">{getTown(form)}</td>
								<td className="px-2 py-3 text-sm text-gray-500 W-4/12 whitespace-nowrap">{getTruckName(form.requestedModelId)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

		</>
	);
};

export default ListFormsInlet;
