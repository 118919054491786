import React, { useState } from 'react';
import logo from '../assets/dante_logo.png';
import './loginComponent.css';
import { useDanteStore } from '../store/store';
import { AuthResult } from '../models/AuthResult';
import Spinner from './spinner';

export const LoginComponent: React.FC = () => {

	const rootState = useDanteStore((state) => state);
	const [authState, setAuthState] = useState<AuthResult>();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [logging, setLogging] = useState(false);

	const handleLogin = async (email: string, password: string) => {
		console.log(`[auth] trying login with ${email}/${password}`);
		let authResult = {
			isAuthenticated: false,
			error: ''
		} as AuthResult;
		if (email && password) {
			setLogging(true);
			authResult = (await rootState.tryLogin(email, password));
		} else {
			setError('Lütfen email ve şifre girin.');
		}
		setLogging(false);
		if (authResult.isAuthenticated) {
			console.log('[auth] dispatching redux slices');
			setAuthState(authResult);
		} else {
			switch (authResult.error) {
				case 'NoSuchEmail':
					setError('Bu email sistemde kayıtlı değil.');
					break;
				case 'WrongPassword':
					setError('Yanlış şifre.');
					break;
				case 'InactiveAccount':
					setError('Bu hesap aktif değildir.');
					break;
			}
		}
	};


	return (
		<div className="absolute grid items-center justify-center w-full h-full">
			{logging && <Spinner />}
			<div className="loginContainer">
				<div className="round-xl">
					<div className="grid justify-center mb-4">
						<img
							src={logo}
							alt="Dante"
							className="p-4 bg-white border-2 border-gray-800 rounded h-30"
						/>
					</div>
					{authState?.error && <p className="mb-4 text-red-500">{authState.error}</p>}
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleLogin(email, password);
						}}>
						<div className="mb-4">
							<label className="block mb-2 text-sm font-medium text-white" htmlFor="email">
								E-posta
							</label>
							<input
								type="email"
								id="email"
								className="w-full p-2 text-xl border rounded-md font-extralight"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								autoComplete="email"
							/>
						</div>
						<div className="mb-4">
							<label className="block mb-2 text-sm font-medium text-white" htmlFor="password">
								Şifre
							</label>
							<input
								type="password"
								id="password"
								className="w-full p-2 text-xl border rounded-md font-extralight"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								autoComplete="current-password"
							/>
						</div>
						{
							error && (<div><p className="text-center text-red-600">{error}</p></div>)
						}
						<button
							type="submit"
							className="loginButton">
							Giriş
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};
