import { DateTime } from 'luxon';
import validator from 'email-validator';
import TimeAgo from 'javascript-time-ago';

class Tools {
	static isNullOrEmpty(input: string | null | undefined): boolean {
		return input === null || input === undefined || input.trim() === '';
	}

	static isValidNumber(input: number | null | undefined): boolean {
		return input !== null && input !== undefined && input > 0;
	}

	static isValidEmail(input: string | null | undefined): boolean {
		if (input === null || input === undefined) return false;

		return validator.validate(input ?? '');

		// var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

		// // Test the email against the pattern
		// return emailPattern.test(input);

	}

	static GeneratePassword(length = 6): string {
		// Define the set of characters that can be used in the password
		const charset = 'abcdefghjklmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789!';

		// Initialize an empty password
		let password = '';

		// Loop to randomly select characters from the charset
		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * charset.length);
			password += charset[randomIndex];
		}

		return password;
	}

	public static getDaysAgo = (date): string | undefined => {
		if (date == undefined) return '';
		if (typeof date === 'string') {
			//console.log(`[getDaysAgo][str] ${date}`);
			date = DateTime.fromISO(date);
		} else {
			//console.log(`[getDaysAgo][date] ${date.toString()}`);
		}
		const d = DateTime.fromISO(date.toString());
		const timeAgo = new TimeAgo('tr-TR');
		return timeAgo.format(d.toJSDate());

	};

	public static displayPhoneNumber(input: string | null | undefined, showCountry: boolean = true): string {
		let output = '';
		const country = '(0)';

		//+90(332)532 9150
		//+90(566)094 4277
		//+90(713)506 7511
		// 533 697 8658
		// 533 697 8658
		if (input !== undefined && input !== null && input.length == 10) {
			const operator = input.substring(0, 3);
			const rest1 = input.substring(3, 6);
			const rest2 = input.substring(6, 10);
			output = showCountry ? `${country}${operator}-${rest1}-${rest2}` : `${operator}-${rest1}-${rest2}`;
		}

		//Server=tcp:your_server.database.windows.net;Initial Catalog=your_db;Persist Security Info=False;User ID=your_username;Password=your_password;MultipleActiveResultSets=False;Encrypt=True;TrustServerCertificate=False;Connection Timeout=30;Pooling=true;Max Pool Size=100;Min Pool Size=0;

		return output;
	}

	public static generateRandomString(length) {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	public static stringToColor(str: string): string {
		// Hash function to convert the string into a number
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash) + 42;
		}

		// Converting hash to RGB components
		const red = (hash >> 16) & 0xFF;
		const green = (hash >> 8) & 0xFF;
		const blue = hash & 0xFF;

		// Softening the color by ensuring the components are not too low or too high
		const soften = (color: number): number => Math.round((color % 128) + 80);

		// Formatting to HEX
		const toHex = (c: number): string => {
			const hex = c.toString(16);
			return hex.length == 1 ? '0' + hex : hex;
		};

		return `${toHex(soften(red))}${toHex(soften(green))}${toHex(soften(blue))}`;

	}

	public static formatDate = (date): string => {
		if (date == undefined) return '';
		if (typeof date === 'string') {
			//console.log(`[getDaysAgo][str] ${date}`);
			date = DateTime.fromISO(date);
		}
		const d = DateTime.fromISO(date.toString());
		const formattedDate = `${d.day}-${d.month + 1}-${d.year}`;
		return formattedDate;
	};

	public static getAvatar = (name: string | undefined) => {
		const namecolor = Tools.stringToColor(name ?? 'Dante Sales');
		return `https://ui-avatars.com/api/?color=FFF&background=${namecolor}&name=${name ?? 'Dante Sales'}`;
	};

	public static displayDate(input: DateTime | undefined): string {
		return input?.toString().substring(0, 19).replace('T', ' ') ?? '';
	}

	public static FormatDate(input: string | number | Date | undefined): string {
		const date = new Date(input ?? Date.now.toString());
		const day = ('0' + date.getDate()).slice(-2); // Get day and prepend zero if necessary
		const month = ('0' + (date.getMonth() + 1)).slice(-2); // Get month (0-indexed) and prepend zero
		const year = date.getFullYear();

		return `${day}-${month}-${year}`;
	}

	public static FormatCurrency(value, currency, locale = 'tr-TR'): string {
		if (currency == 'TL') {
			currency = 'TRY';
		}
		if (currency.toLowerCase() == 'euro') {
			currency = 'EUR';
		}
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currency
		}).format(value);
	}

	public static toTitleCase(input: string): string {
		return input.replace(/\w\S*/g, (word) => {
			return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
		});
	}

}

export default Tools;
