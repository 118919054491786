import React from 'react';
import { useDanteStore } from '../store/store';
import SalesBranch from '../models/SalesBranch';
import { User } from '../models/User';
import { navigate } from 'wouter/use-browser-location';
import userService from '../services/userService';
import './userform.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'flowbite-react';

const UserNewInlet: React.FC = () => {

	const rootState = useDanteStore((state) => state);
	const [saving, setSaving] = React.useState(false);

	const formik = useFormik({
		initialValues: {
			fullName: '',
			email: '',
			password: '',
			type: 0,
			managerId: '',
			salesBranchId: 0,
			isActive: true,
		},
		onSubmit: async (values) => {
			setSaving(true);
			console.log('onsubmit', values);
			var result = await userService.Create(token!, values);
			console.log('result', result);
			rootState.fetchUsers();
			setSaving(false);
			navigate('/users');
		},
		validationSchema: Yup.object({
			fullName: Yup.string()
				.min(2, 'En az 2 karakter girmelisiniz')
				.required('İsim giriniz'),
			email: Yup.string()
				.email('Geçerli bir e-posta adresi giriniz')
				.required('E-posta giriniz'),
			password: Yup.string()
				.min(6, 'En az 6 karakter girmelisiniz')
				.required('Şifre giriniz'),
			type: Yup.number()
				//.min(100, 'Kullanıcı rolü seçiniz')
				.required('Kullanıcı rolü seçiniz'),
			managerId: Yup.string()
				.min(2, 'Yönetici seçiniz')
				.required('Yönetici seçiniz'),
			salesBranchId: Yup.number()
				.min(1, 'Şube seçiniz')
				.required('Şube seçiniz')
		}),
	});


	const token = useDanteStore((state) => state.auth?.accessToken);
	const branches: SalesBranch[] = useDanteStore((state) => state.branches);
	const users: User[] = useDanteStore((state) => state.users);

	const managers = users.filter((u) => u.type! >= 150 && u.email != 'umut@celenli.com');

	return (

		<form onSubmit={formik.handleSubmit}>
			<div className="h-full">

				<div className='p-4 bg-blue-300 rounded-sm'>Yeni Kullanıcı</div>

				<div className='flex flex-row mb-12'>
					<div className='w-full p-5 bg-gray-50'>
						<div className='w-full'>

							<div className='p-4 bg-gray-200 rounded-lg'>
								<div className='error'>
									{formik.errors.fullName && formik.touched.fullName && formik.errors.fullName}
								</div>
								<label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
									İsim
								</label>
								<div className="mt-2">
									<input
										type="text"
										name="fullName"
										autoComplete="off"
										value={formik.values.fullName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="nameInput"
									/>
								</div>

							</div>

							<div className='p-4 mt-1 bg-gray-200 rounded-lg'>
								<div className='error'>
									{formik.errors.email && formik.touched.email && formik.errors.email}
								</div>
								<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
									E-posta
								</label>
								<div className="mt-2">
									<input
										id="email"
										name="email"
										type="email"
										value={formik.values.email}
										autoComplete="off"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="emailInput"
									/>
								</div>
							</div>

							<div className='p-4 mt-1 bg-gray-200 rounded-lg'>
								<div className='error'>
									{formik.errors.password && formik.touched.password && formik.errors.password}
								</div>
								<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
									Şifre
								</label>
								<div className="flex mt-2">
									<input
										id="password"
										name="password"
										type="text"
										value={formik.values.password}
										autoComplete="off"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="block w-1/2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									/>
								</div>
							</div>

							<div className='p-4 mt-1 bg-gray-200 rounded-lg'>
								<div className='error'>
									{formik.errors.type && formik.touched.type && formik.errors.type}
								</div>
								<label htmlFor="userType" className="block text-sm font-medium leading-6 text-gray-900">
									Kullanıcı Rolü
								</label>
								<div className="mt-2">
									<select
										name="type"
										value={formik.values.type}
										onChange={formik.handleChange}
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
									>
										<option value={0}>Seçiniz</option>
										<option value={100}>İkinci El uzmanı</option>
										<option value={150}>Satış Mümessili</option>
										<option value={170}>Satış Sonrası Destek</option>
										<option value={200}>Satış Müdürü</option>
										<option value={250}>Yönetim</option>
										<option value={255}>Administrator</option>
									</select>
								</div>
							</div>

							<div className='p-4 mt-1 bg-gray-200 rounded-lg'>
								<div className='error'>
									{formik.errors.managerId && formik.touched.managerId && formik.errors.managerId}
								</div>
								<label htmlFor="managerId" className="block text-sm font-medium leading-6 text-gray-900">
									Yönetici
								</label>
								<div className="mt-2">
									<select
										name="managerId"
										value={formik.values.managerId}
										onChange={formik.handleChange}
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
									>
										<option key="MAN0" value="0">Seçiniz</option>
										{managers.map((b) => (
											<option key={`MAN_${b.userId}`} value={b.userId}>
												{b.fullName}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className='p-4 mt-1 bg-gray-200 rounded-lg'>
								<div className='error'>
									{formik.errors.salesBranchId && formik.touched.salesBranchId && formik.errors.salesBranchId}
								</div>
								<label htmlFor="salesBranchId" className="block text-sm font-medium leading-6 text-gray-900">
									Şube
								</label>
								<div className="mt-2">
									<select
										name="salesBranchId"
										value={formik.values.salesBranchId}
										onChange={formik.handleChange}
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
									>
										<option key="BRANCH_0" value="0">Seçiniz</option>
										{branches.map((b) => (
											<option key={`BRANCH_${b.id}`} value={b.id}>
												{b.name}
											</option>
										))}
									</select>
								</div>

							</div>

						</div>

						<div className="w-full p-2 bg-gray-100">
							<div className="flex items-center justify-end mt-6 gap-x-3">
								<Button color="green" type="submit">
									{saving && <span className="absolute inline-flex w-8 h-8 ml-6 rounded-full opacity-75 animate-ping bg-sky-400"></span>}
									Kaydet
								</Button>
								<Button color="red" onClick={() => navigate('/users')}>Kapat</Button>
							</div>
						</div>

					</div>

				</div>
			</div>
		</form>
	);
};



export default UserNewInlet;

