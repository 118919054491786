/* eslint-disable no-unused-vars */
import { create } from 'zustand';
import { City } from '../models/Geo';
import { User } from '../models/User';
import { AuthResult } from '../models/AuthResult';
import { Truck } from '../models/Truck';
import { Axios } from 'axios';
import { Form } from '../models/Form';
import Alert from '../models/Alert';
import Comment from '../models/Comment';
import Brand from '../models/Brand';
import TruckGroup from '../models/TruckGroup';
import TruckVariant from '../models/TruckVariant';
import MotorType from '../models/MotorType';
import MotorControl from '../models/MotorControl';
import WheelDrive from '../models/WheelDrive';
import GearBox from '../models/GearBox';
import Shuttle from '../models/Shuttle';
import PTO from '../models/PTO';
import Color from '../models/Color';
import ClientType from '../models/ClientType';
import SalesBranch from '../models/SalesBranch';
import { AppRegistry } from '../models/AppRegistry';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const BASE_ENDPOINT_URL = `${BACKEND_URL}/api`;
const VERSIONS_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/version`;
const BRANDS_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/brands`;
const BRANCHES_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/branches`;
const TRUCKGROUPS_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/truckgroups`;
const TRUCKCASES_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/truckvariants`;
const MOTORTYPES_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/motortypes`;
const MOTORCONTROLS_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/motorcontrols`;
const WHEELDRIVES_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/wheeldrives`;
const GEARBOXES_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/gearboxes`;
const SHUTTLES_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/shuttles`;
const PTOS_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/ptos`;
const COLORS_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/colors`;
const CLIENTTYPES_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/meta/clienttypes`;
const TRUCKS_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/truck/raw`;

const NOTES_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/note/list`;
const LOGIN_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/identity/login`;
const USERS_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/identity/users`;
const CITIES_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/geo/cities`;
const ALERTS_LIST_URL = `${BASE_ENDPOINT_URL}/alert/list`;
const FORMS_ENDPOINT_URL = `${BASE_ENDPOINT_URL}/form/listAll`;
const FORMS_CREATE_URL = `${BASE_ENDPOINT_URL}/form/create`;
const FORMS_UPDATE_URL = `${BASE_ENDPOINT_URL}/form/update`;

const REQUIRED_SET =
	['trucks', 'users', 'cities', 'forms', 'brands', 'truckGroups', 'truckVariants', 'colors', 'clientTypes', 'registry', 'branches'];

const REQUIREDREST_SET = ['motorTypes', 'motorControls', 'wheelDrives', 'gearBoxes', 'shuttles', 'ptos',];

export type State = {
	loading: boolean;
	auth?: AuthResult;
	alerts: Alert[];
	users: User[];
	cities: City[];
	trucks: Truck[];
	forms: Form[];
	comments: Comment[];
	branches: SalesBranch[];

	brands: Brand[];
	truckGroups: TruckGroup[];
	truckVariants: TruckVariant[];
	motorTypes: MotorType[];
	motorControls: MotorControl[];
	wheelDrives: WheelDrive[];
	gearBoxes: GearBox[];
	shuttles: Shuttle[];
	ptos: PTO[];
	colors: Color[];
	registry: AppRegistry;
	clientTypes: ClientType[];
	loadedSets: string[];
	allLoaded: boolean;
};

export type Actions = {
	setAuth: (newAuth: AuthResult) => void;
	setLoading: (loading: boolean) => void;
	tryLogin: (email: string, password: string) => Promise<AuthResult>;
	logout: () => void;

	fetchRegistry: () => void;
	fetchAlerts: () => void;
	fetchUsers: () => void;
	fetchCities: () => void;
	fetchTrucks: () => void;
	fetchForms: () => void;

	fetchBrands: () => void;
	fetchBranches: () => void;
	fetchTruckGroups: () => void;
	fetchTruckVariants: () => void;
	fetchMotorTypes: () => void;
	fetchMotorControls: () => void;
	fetchWheelDrives: () => void;
	fetchGearBoxes: () => void;
	fetchShuttles: () => void;
	fetchPTOs: () => void;
	fetchColors: () => void;

	fetchClientTypes: () => void;
	createForm: (form: Form, alert: Alert) => Promise<Form>;
	updateForm: (form: Form) => Promise<Form>;
};

const getAxios = (accesstoken?: string | null | undefined) => {
	const config = accesstoken
		? {
			headers: {
				Authorization: `Bearer ${accesstoken}`,
				'Content-Type': 'application/json',
			},
		}
		: {
			headers: {
				'Content-Type': 'application/json',
			},
		};
	return new Axios(config);
};
const addSetKey = (get, set, key) => {
	set({ loadedSets: [...get().loadedSets.filter(x => x != key), key] });
	console.log(`[store] loadedSets: ${get().loadedSets.length}`);
	const loadedCount = get().loadedSets.length;
	const loaded = loadedCount == REQUIRED_SET.length;
	set({ allLoaded: loaded });
	console.log(get().loadedSets);
	console.log(`[store] all loaded: ${loaded} (${loadedCount}/${REQUIRED_SET.length})`);
};

const fetchData = async (get, set, key, url) => {
	const state = get();
	const accessToken = state.auth?.accessToken;
	set({ loading: true });
	const response = await getAxios(accessToken).get(url);
	const data = JSON.parse(response.data);
	console.log(`[store] ${data.length} ${key} loaded`, data);
	//set({ loading: false, alerts: data });
	addSetKey(get, set, key);
	set({ loading: false, [key]: data });
};

export const externalLogin = async (email: string, password: string): Promise<AuthResult> => {
	const postData = { email: email, password: password };
	const response = await getAxios().post(LOGIN_ENDPOINT_URL, JSON.stringify(postData));
	return JSON.parse(response.data);
};

export const useDanteStore = create<State & Actions>((set, get) => ({
	loading: false,
	loadedSets: [],
	users: [],
	cities: [],
	trucks: [],
	forms: [],
	alerts: [],
	comments: [],
	branches: [],

	brands: [],
	truckGroups: [],
	truckVariants: [],
	motorTypes: [],
	motorControls: [],
	wheelDrives: [],
	gearBoxes: [],
	shuttles: [],
	ptos: [],
	colors: [],
	clientTypes: [],
	registry: new AppRegistry(),
	allLoaded: false,

	auth: {
		accessToken: undefined,
		error: undefined,
		isAuthenticated: false,
		user: undefined,
	},

	setAuth: (newAuth: AuthResult) => set(() => ({ auth: newAuth })),
	setLoading: (loading: boolean) => set(() => ({ loading: loading })),
	tryLogin: async (email: string, password: string): Promise<AuthResult> => {
		set({ loading: true });
		const postData = { email: email, password: password };
		const response = await getAxios().post(LOGIN_ENDPOINT_URL, JSON.stringify(postData));
		const result = JSON.parse(response.data);
		set({ loading: false, auth: result });
		return result;
	},
	logout: async (): Promise<void> => {
		console.log('Before logout:', get().auth);
		set({
			auth: {
				accessToken: undefined,
				error: undefined,
				isAuthenticated: false,
				user: undefined,
			},
		});
		console.log('After logout:', get().auth);
	},

	fetchAlerts: async () => { await fetchData(get, set, 'alerts', ALERTS_LIST_URL); },
	fetchUsers: async () => { await fetchData(get, set, 'users', USERS_ENDPOINT_URL); },
	fetchForms: async () => { await fetchData(get, set, 'forms', FORMS_ENDPOINT_URL); },
	fetchCities: async () => { await fetchData(get, set, 'cities', CITIES_ENDPOINT_URL); },
	fetchBrands: async () => { await fetchData(get, set, 'brands', BRANDS_ENDPOINT_URL); },
	fetchBranches: async () => { await fetchData(get, set, 'branches', BRANCHES_ENDPOINT_URL); },
	fetchTruckGroups: async () => { await fetchData(get, set, 'truckGroups', TRUCKGROUPS_ENDPOINT_URL); },
	fetchTruckVariants: async () => { await fetchData(get, set, 'truckVariants', TRUCKCASES_ENDPOINT_URL); },
	fetchMotorTypes: async () => { await fetchData(get, set, 'motorTypes', MOTORTYPES_ENDPOINT_URL); },
	fetchMotorControls: async () => { await fetchData(get, set, 'motorControls', MOTORCONTROLS_ENDPOINT_URL); },
	fetchWheelDrives: async () => { await fetchData(get, set, 'wheelDrives', WHEELDRIVES_ENDPOINT_URL); },
	fetchGearBoxes: async () => { await fetchData(get, set, 'gearBoxes', GEARBOXES_ENDPOINT_URL); },
	fetchShuttles: async () => { await fetchData(get, set, 'shuttles', SHUTTLES_ENDPOINT_URL); },
	fetchPTOs: async () => { await fetchData(get, set, 'ptos', PTOS_ENDPOINT_URL); },
	fetchColors: async () => { await fetchData(get, set, 'colors', COLORS_ENDPOINT_URL); },
	fetchClientTypes: async () => { await fetchData(get, set, 'clientTypes', CLIENTTYPES_ENDPOINT_URL); },
	fetchComments: async () => { await fetchData(get, set, 'notes', NOTES_ENDPOINT_URL); },
	fetchRegistry: async () => { await fetchData(get, set, 'registry', VERSIONS_ENDPOINT_URL); },

	fetchTrucks: async () => {
		const state = get();
		const accessToken = state.auth?.accessToken;
		set({ loading: true });
		const response = await getAxios(accessToken).get(TRUCKS_ENDPOINT_URL);
		const data = JSON.parse(response.data);
		console.log(`[store] ${data.length} trucks loaded`, data);
		addSetKey(get, set, 'trucks');

		set({ loading: false, trucks: data });
	},
	createForm: async (form: Form, alert: Alert): Promise<Form> => {
		const state = get();
		console.log(`[store] ${state.forms.length} forms loaded`);
		console.log('[store] saving form ', form);
		const accessToken = state.auth?.accessToken;
		set({ loading: true });
		const payload = { Form: form, Alert: alert };
		const response = await getAxios(accessToken).post(FORMS_CREATE_URL, JSON.stringify(payload));
		const data = JSON.parse(response.data);
		console.log('[store] saved form ', data);
		set({ loading: false, forms: [...state.forms, data] });
		console.log(`[store] ${[...state.forms, data].length} forms loaded`);
		return data;
	},
	updateForm: async (form: Form): Promise<Form> => {
		const state = get();
		console.log(`[store] ${state.forms.length} forms loaded`);
		console.log('[store] updating form ', form);
		const accessToken = state.auth?.accessToken;
		set({ loading: true });
		const response = await getAxios(accessToken).put(FORMS_UPDATE_URL, JSON.stringify(form));
		const data = JSON.parse(response.data);
		console.log('[store] updated form ', data);
		const oldState = state.forms.filter((x) => x.formId != data.formId);
		set({ loading: false, forms: [...oldState, data] });
		console.log(`[store] ${[...state.forms, data].length} forms loaded`);
		return data;
	}
}));
