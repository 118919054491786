import React from 'react';
import { useDanteStore } from '../store/store';
import './versionComponent.css';

export const VersionComponent: React.FC = () => {
	const registry = useDanteStore((state) => state.registry);

	return (
		<>
			<div className="absolute z-50 h-26 w-72 bottom-1 right-1">
				<div className="p-1 m-2 bg-white border-l-8 border-green-600 rounded-r cursor-pointer">
					<div className='flex flex-row w-full mb-0.5'>
						<div className="w-5/12 regLine">uygulama versiyon</div>
						<div className="w-7/12 valLine">{registry.AppVersion}</div>
					</div>
					<div className='flex flex-row w-full mb-0.5'>
						<div className="w-5/12 regLine">excel versiyon</div>
						<div className="w-7/12 valLine">{registry.ExcelVersion}</div>
					</div>
					<div className='flex flex-row w-full mb-0.5'>
						<div className="w-5/12 regLine">son güncelleme</div>
						<div className="w-7/12 valLine">{registry.BuildDate}</div>
					</div>
				</div>
				<div className='mr-2'>
					<a href="https://dev.azure.com/danteticaret/Dante%20Web/_boards/board/t/Dante%20Web%20Team/Issues" target="_blank" rel="noreferrer">
						<img src="https://dev.azure.com/danteticaret/9082675b-13e3-493e-9de4-baedb749b3d7/8e96d949-f980-4cfb-b036-f1200cc9c2e3/_apis/work/boardbadge/3f325ee2-e2f2-4dc1-ba93-5d7a12acdb99?columnOptions=1" alt="Dante Web Project" />
					</a>
				</div>
			</div>
		</>
	);
};
