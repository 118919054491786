import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const FILE_URL = `${BACKEND_URL}/api/file`;

class FileService {
	constructor() {}

	private getConfig(token: string) {
		return {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		};
	}

	async Upload(token: string, file: File, filename: string) {
	  console.log('[form] saving new file',filename);
		try {
			const response = await axios.post(`${FILE_URL}/upload`, file, this.getConfig(token));
			return response.data;
		} catch (error) {
			console.error('Error sending contact form data:', error);
			throw error;
		}
	}

}

export default new FileService();
