import React, { useEffect, useState } from 'react';
import { useDanteStore } from '../store/store';
import { useParams } from 'wouter';
import { Form } from '../models/Form';
import { Truck } from '../models/Truck';
import { DateTime } from 'luxon';
import Tools from '../services/tools';
import Brand from '../models/Brand';
import { navigate } from 'wouter/use-browser-location';
import { City } from '../models';
import TruckGroup from '../models/TruckGroup';
import UsageList from '../models/UsageList';
import { User } from '../models/User';
import SalesBranch from '../models/SalesBranch';
import Comment from '../models/Comment';
import CommentService from '../services/commentService';
import { CommentAction, CommentComponent } from '../components/CommentComponent';
import { Button, Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import formService from '../services/formService';

const OrderInlet: React.FC = () => {

	const params = useParams();
	const formId = params?.id ?? undefined;
	const rootState = useDanteStore((state) => state);
	const token = useDanteStore((state) => state.auth?.accessToken);
	const trucks: Truck[] = useDanteStore((state) => state.trucks);
	const truckGroups: TruckGroup[] = useDanteStore((state) => state.truckGroups);
	const brands: Brand[] = useDanteStore((state) => state.brands);
	const branches: SalesBranch[] = useDanteStore((state) => state.branches);

	const cities: City[] = useDanteStore((state) => state.cities);
	const forms: Form[] = useDanteStore((state) => state.forms);
	const users: User[] = useDanteStore((state) => state.users);
	const [form, setForm] = useState<Form>();
	const [truck, setTruck] = useState<Truck>();

	//const myComments = useComments(formId);
	const [comments, setComments] = useState<Comment[]>([]);
	const [openModal, setOpenModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [message, setMessage] = useState('');
	const [commentId, setCommentId] = useState('');
	const [lastupdate, setLastUpdate] = useState({ tick: '' });

	useEffect(() => {
		const loadedForm = formId ? forms.filter((x) => x.formId == parseInt(formId))[0] : undefined;
		const loadedTruck = loadedForm ? trucks.filter((x) => x.id == loadedForm?.requestedModelId)[0] : undefined;

		if (loadedForm) {
			setForm(loadedForm);
			setTruck(loadedTruck);
		}

	}, [rootState]);

	useEffect(() => {
		const fetchComments = async () => {
			console.log(`fetching comments for form ${formId} with ticx ${lastupdate.tick}`);
			const results = await CommentService.GetComments(token, formId);
			setComments(results);
			console.log(`fetched comments for form ${formId} with ticx ${lastupdate.tick}`, results);
		};
		fetchComments();
	}, [lastupdate.tick]);

	const createComment = async (token: string, comment: Comment, files: File[]): Promise<Comment | undefined> => {
		console.log(`called create comment ${token}`, comment);
		const newComment = await CommentService.CreateComment(token, comment, files);
		setLastUpdate({ tick: DateTime.now().toString() });
		return newComment;
	};

	const deleteComment = async (token: string, commentId: string | undefined): Promise<void> => {
		console.log(`called delete comment ${commentId}`);
		await CommentService.DeleteComment(token, commentId);
		setLastUpdate({ tick: DateTime.now().toString() });
	};

	const deleteForm = async (): Promise<void> => {
		const token = rootState.auth?.accessToken;
		console.log(`called delete form ${formId}`);
		await formService.Delete(token!, formId!);
		await rootState.fetchForms();
		navigate('/forms');
	};

	const openEditWindow = async (token: string, commentId: string | undefined): Promise<void> => {
		console.log(`called openEditWindow comment ${commentId}`);
		setCommentId(commentId ?? '');
		const comment = comments.find(x => x.id == commentId);
		setMessage(comment?.message ?? '');
		setOpenModal(true);
	};

	const updateComment = async () => {
		const token = rootState.auth?.accessToken;
		if (message.trim() == '') {
			setLastUpdate({ tick: DateTime.now().toString() });
			setOpenModal(false);
			return;
		}
		const comment = await CommentService.UpdateComment(token, commentId, message);
		if (comment) {
			const newComments = [
				...comments.filter(x => x.id != commentId),
				comment
			].sort((a, b) => a.createDate! > b.createDate! ? 1 : -1);
			setComments(newComments);
			console.log('updated comments', newComments);
		}
		setLastUpdate({ tick: DateTime.now().toString() });
		setOpenModal(false);
	};

	const showFacebookLink = () => {
		if (form?.facebook == undefined || form?.facebook == '') return 'Yok';
		return (
			<a className='text-blue-800 underline text-bold' href={`https://tr-tr.facebook.com/${form?.facebook}`} target='_blank' rel='noreferrer'>
				{form?.facebook}
			</a>
		);
	};

	const showInstagramLink = () => {
		if (form?.instagram == undefined || form?.instagram == '') return 'Yok';
		return (
			<a className='text-blue-800 underline text-bold' href={`https://www.instagram.com/${form?.instagram}`} target='_blank' rel='noreferrer'>
				{form?.instagram}
			</a>
		);
	};

	const showTiktokLink = () => {
		if (form?.tiktok == undefined || form?.tiktok == '') return 'Yok';
		return (
			<a className='text-blue-800 underline text-bold' href={`https://www.tiktok.com/@${form?.tiktok}`} target='_blank' rel='noreferrer'>
				{form?.tiktok}
			</a>
		);
	};

	const ShowSalesRep = () => {
		const salesRep = users.find(x => x.userId == form?.salesRepUserId);
		return salesRep?.fullName;
	};

	const ShowSalesBranch = () => {
		const salesRep = users.find(x => x.userId == form?.salesRepUserId);
		const branch = branches.find(x => x.id == salesRep?.branchId);
		return `${branch?.name ?? 'Türkiye'}`;
	};

	const goEdit = (id: number | undefined) => {
		if (id) {
			navigate(`/forms/${id}`);
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (e.target.value || e.target.value == '') {
			setMessage(e.target.value.trim());
		}
	};

	const toDateString = (date?: string) => (date == undefined) ? '' : DateTime.fromISO(date).toFormat('dd/MM/yyyy');
	const toHourString = (date?: string) => (date == undefined) ? '' : DateTime.fromISO(date).toFormat('HH:mm');
	const ShowUsage = () => UsageList.find(x => x.value == truck?.type)?.label;
	const ShowBrand = () => brands.find(x => x.id == truck?.brandId)?.name;
	const ShowGroup = () => truckGroups.find(x => x.id == truck?.groupId)?.name;
	const ShowModel = () => trucks.find(x => x.id == truck?.id)?.model;
	const ShowCity = (id?: number) => cities.find(x => x.id == id)?.name;
	const ShowDistrict = (id?: number) => cities.find(x => x.id == form?.cityId)?.districts.find(x => x.id == id)?.name;
	const ShowNeighbourhood = (id?: number) => cities.find(x => x.id == form?.cityId)?.districts.find(x => x.id == form?.districtId)?.neighbourhoods.find(x => x.id == id)?.name;

	return (
		<div className='flex flex-col w-full gap-3 p-5 overflow-y-auto text-sm'>
			<div className='data-section'>
				<div className='text-white rounded-t-lg bg-gradient-to-b from-blue-800 to-blue-900'>
					<h1 className='p-2 text-2xl font-bold text-center'>Form {form?.formId}</h1>
				</div>
				<div className='flex flex-col p-4 rounded-b-lg bg-gradient-to-b from-blue-200 to-teal-300 '>
					<div className='flex flex-row w-full'>
						<div className='w-1/2'><b>Mümessil:</b>  {ShowSalesRep()}</div>
						<div className='w-1/2 text-right'><b>Tarih:</b> {toDateString(form?.createDate?.toString())}</div>
					</div>
					<div className='flex flex-row w-full'>
						<div className='w-1/2'><b>Şube:</b>  {ShowSalesBranch()}</div>
						<div className='w-1/2 text-right'><b>Saat:</b> {toHourString(form?.createDate?.toString())}</div>
					</div>
					<div className='mt-1'>
						<Button color='blue' size='xs' className='float-right ml-1' onClick={() => { goEdit(form?.formId); }}>Formu düzenle</Button>
						<Button color='red' size='xs' className='float-right ml-1' onClick={() => { setDeleteModal(true); }}>Formu sil</Button>
					</div>
				</div>
				<div className='flex flex-col p-4 mt-2 bg-gray-200 rounded'>
					<div className='flex flex-row w-full'>
						<div className='w-3/4'><b>İsim:</b>{form?.fullName}</div>
						<div className='w-1/4 text-right'><b>Arazi:</b> {form?.farmAreaDeca} dekar</div>
					</div>
					<div className='flex flex-row w-full'>
						<div className='w-3/4'><b>Telefon:</b>{Tools.displayPhoneNumber(form?.phone)}</div>
						<div className='w-1/4 text-right'><b>Hayvan:</b> {form?.animalCount} adet</div>
					</div>
					<div className='flex flex-row w-full'>
						<div className='w-3/4'><b>Adres:</b>{form?.address}</div>
						<div className='w-1/4 text-right'><b>Facebook:</b> {showFacebookLink()}</div>
					</div>
					<div className='flex flex-row w-full'>
						<div className='w-3/4'><b>Mahalle:</b>{ShowNeighbourhood(form?.neighbourhoodId)}</div>
						<div className='w-1/4 text-right'><b>Instagram:</b> {showInstagramLink()}</div>
					</div>
					<div className='flex flex-row w-full'>
						<div className='w-3/4'><b>İlçe:</b>{ShowDistrict(form?.districtId)}</div>
						<div className='w-1/4 text-right'><b>Tiktok:</b> {showTiktokLink()}</div>
					</div>
					<div className='flex flex-row w-full'>
						<div className='w-3/4'><b>Şehir:</b>{ShowCity(form?.cityId)}</div>
						<div className='w-1/4 text-right'></div>
					</div>
				</div>
				<div className='flex flex-col p-4 mt-2 bg-gray-200 rounded'>
					<div><b>Kullanım amacı:</b>{ShowUsage()}</div>
					<div><b>İstenen marka:</b>{ShowBrand()}</div>
					<div><b>İstenen grup:</b>{ShowGroup()}</div>
					<div><b>İstenen model:</b>{ShowModel()}</div>
					<div><b>Takas:</b>{form?.exchangeWith ?? 'Yok'}</div>
				</div>
			</div>
			<div className=''>
				<ul role="list">
					<li><input type="hidden" value={lastupdate.tick} /></li>
					{comments.map((comment) => (
						<CommentComponent key={`${comment.id}_${lastupdate.tick}`} action={CommentAction.Read} comment={comment} formId={formId} deleteComment={deleteComment} editComment={openEditWindow} />
					))
					}
				</ul>
				<ul>
					<CommentComponent key="cc_new" action={CommentAction.New} formId={formId} createComment={createComment} />
				</ul>
			</div>
			<Modal show={openModal} onClose={() => setOpenModal(false)}>
				<Modal.Header>Yorum/Not düzenle</Modal.Header>
				<Modal.Body>
					<div className="space-y-6">
						<textarea rows={3} className='w-full h-20 text-sm leading-6 text-gray-500 border-none resize-none' onChange={handleChange} value={message} />
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={async () => await updateComment()}>Güncelle</Button>
					<Button color="red" onClick={() => setOpenModal(false)}>İptal</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={deleteModal} size="md" onClose={() => setDeleteModal(false)} popup>
				<Modal.Header />
				<Modal.Body>
					<div className="text-center">
						<HiOutlineExclamationCircle className="mx-auto mb-4 text-gray-400 h-14 w-14 dark:text-gray-200" />
						<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
							Formu siliyorsunuz, emin misiniz? Bu işlem veri kaybına yol açacaktır.
						</h3>
						<div className="flex justify-center gap-4">
							<Button color="failure" onClick={deleteForm}>
								{"Evet"}
							</Button>
							<Button color="gray" onClick={() => setDeleteModal(false)}>
								Hayır
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>

	);
};

export default OrderInlet;
