import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { XCircleIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import './alertComponent.css';
import { AlertType } from '../models/AlertType';

interface AlertProps {
    type: AlertType;
    title: string;
    message: string;
    onRemove?: () => void;
}

const AlertComponent = (props: AlertProps) => {
	const { type, title, message, onRemove } = props;
	let color = '';
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setVisible(false);
			const removalTimer = setTimeout(() => {
				if (onRemove) {
					onRemove();
				}
			}, 500);
			return () => clearTimeout(removalTimer);
		}, 2500); //
		return () => clearTimeout(timer);
	}, [onRemove]);

	switch (type) {
	case 'success':
		color = 'Success';
		break;
	case 'warning':
		color = 'Warn';
		break;
	case 'fail':
		color = 'Fail';
		break;
	}

	const iconClass = `alertIcon${color}`;
	const titleClass = `alertTitle${color}`;
	const textClass = `alertText${color}`;
	const containerClass = `alertContainer${color}`;

	return (
		<div className={visible ? 'alertbox' : 'alertbox fade-out'}>
			<div className={containerClass}>
				<div className="flex-shrink-0 p-10">
					{type === AlertType.Fail && <XCircleIcon className={iconClass} aria-hidden="true" />}
					{type === AlertType.Warning && <ExclamationTriangleIcon className={iconClass} aria-hidden="true" />}
					{type === AlertType.Success && <CheckCircleIcon className={iconClass} aria-hidden="true" />}
				</div>
				<div className="ml-3">
					<h3 className={titleClass}>{title}</h3>
					<div className={textClass}>
						<p>{message}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AlertComponent;
