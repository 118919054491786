import img1 from '../assets/slides/out/img1.jpg';
import img2 from '../assets/slides/out/img2.jpg';
import img3 from '../assets/slides/out/img3.jpg';
import img4 from '../assets/slides/out/img4.jpg';
import img5 from '../assets/slides/out/img5.jpg';
import img6 from '../assets/slides/out/img6.jpg';
import img7 from '../assets/slides/out/img7.jpg';
import img8 from '../assets/slides/out/img8.jpg';
import img9 from '../assets/slides/out/img9.jpg';
import img10 from '../assets/slides/out/img10.jpg';
import img11 from '../assets/slides/out/img11.jpg';
import img12 from '../assets/slides/out/img12.jpg';
import img13 from '../assets/slides/out/img13.jpg';
import img14 from '../assets/slides/out/img14.jpg';
import img15 from '../assets/slides/out/img15.jpg';
import img16 from '../assets/slides/out/img16.jpg';
import img17 from '../assets/slides/out/img17.jpg';
import img18 from '../assets/slides/out/img18.jpg';
import img19 from '../assets/slides/out/img19.jpg';
import img20 from '../assets/slides/out/img20.jpg';

const images: string[] = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20];

export default { images };
