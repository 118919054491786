import { Form } from '../models/Form';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const FORMS_URL = `${BACKEND_URL}/api/form`;

class FormService {
	constructor() { }

	private getConfig(token: string) {
		return {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		};
	}

	async Create(token: string, data: Form) {
		console.log('[form] saving new form', data);
		try {
			const response = await axios.post(`${FORMS_URL}/create`, data, this.getConfig(token));
			return response.data;
		} catch (error) {
			console.error('Error sending contact form data:', error);
			throw error;
		}
	}

	async Update(token: string, data: Form) {
		console.log('[form] updating existing form with id:' + data.formId);
		try {
			const response = await axios.put(`${FORMS_URL}/${data.formId}`, data, this.getConfig(token));
			return response.data;
		} catch (error) {
			console.error('Error updating contact form data:', error);
			throw error;
		}
	}

	async Delete(token: string, formId: string) {
		console.log('[form] deleting form with id:' + formId);
		try {
			const response = await axios.delete(`${FORMS_URL}/${formId}`, this.getConfig(token));
			return response.data;
		} catch (error) {
			console.error('Error deleting contact form data:', error);
			throw error;
		}
	}

	async Load(token: string | undefined | null, id: number | string): Promise<Form> {
		try {
			if (!token) return '' as any;
			const response = await axios.get(`${FORMS_URL}/${id}`, this.getConfig(token));
			//console.log('[form] loaded form:', response.data);
			const result: Form = response.data;
			console.log('[form] loaded form:', result);
			return result;
		} catch (error) {
			console.error('Error loading form:');
			throw error;
		}
	}




}

export default new FormService();
