import React from "react";
import Money from "../models/Money";
import Tools from "../services/tools";
import { useCookie } from './useCookie';

interface PriceBoxProps {
	prices: Money[]
}

const formatMoney = (price: Money) => {
	if (price === undefined) return '';
	if (price.amount === undefined) return '';
	if (price.currency === undefined) return '';
	return Tools.FormatCurrency(price.amount, price.currency);
};

const PriceBoxComponent = (props: PriceBoxProps) => {
	const { prices } = props;
	const [isHidden, setIsHidden] = useCookie('showPrice', 'false');

	const toggleVisibility = () => {
		setIsHidden(isHidden === 'true' ? 'false' : 'true');
	};

	console.log('[pricebox] prices:', prices);
	return (
		<div className='cursor-pointer form-price' onClick={toggleVisibility}>
			<div className={isHidden === 'true' ? "content-hidden" : "content-visible"}>
				<table className="w-full p-1 text-tiny">
					<tbody>
						<tr className="h-5 p-1 mb-0.5">
							<td className="w-1/2 pr-1 text-right bg-slate-200">Fiyat</td>
							<td className="text-right bg-slate-300">{formatMoney(prices[0])}</td>
						</tr>
						<tr className="h-5 p-1">
							<td className="w-1/2 pr-1 text-right bg-slate-200">Fiyat (+KDV)</td>
							<td className="text-right bg-slate-300">{formatMoney(prices[1])}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);

};

export default PriceBoxComponent;
