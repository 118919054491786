import { useLocation, useParams } from 'wouter';
import React, { useState, useEffect } from 'react';
import { useDanteStore } from '../store/store';
import './form.css';
import ClientType from '../models/ClientType';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import Tools from '../services/tools';
import { Brand, City, District, Truck } from '../models';
import TruckGroup from '../models/TruckGroup';
import TruckVariant from '../models/TruckVariant';
import { Neighbourhood } from '../models/Geo';
import { PhoneInput } from '../components';
import Money from '../models/Money';
import PriceBoxComponent from '../components/priceBox';
import formService from '../services/formService';
import { navigate } from 'wouter/use-browser-location';
import { User } from '../models/User';

const FormEditInlet: React.FC = () => {
	const params = useParams();
	const formId = params?.id ?? undefined;
	const rootState = useDanteStore((state) => state);
	const branches = useDanteStore((state) => state.branches);
	const accessToken = useDanteStore((state) => state.auth?.accessToken);
	const allLoaded = useDanteStore((state) => state.allLoaded);
	const clientTypes: ClientType[] = useDanteStore((state) => state.clientTypes);
	const users = useDanteStore((state) => state.users);
	const [saving, setSaving] = useState(false);
	const [, setLocation] = useLocation();

	const cities: City[] = useDanteStore((state) => state.cities);
	const brands: Brand[] = useDanteStore((state) => state.brands);
	const groups: TruckGroup[] = useDanteStore((state) => state.truckGroups);
	const variants: TruckVariant[] = useDanteStore((state) => state.truckVariants);
	const trucks: Truck[] = useDanteStore((state) => state.trucks);
	const [formUser, setFormUser] = useState<User | undefined>(undefined);
	const [prices, setPrices] = useState<Money[]>([]);

	const [truckModels, setTruckModels] = useState<Truck[]>([]);
	const [truckGroups, setTruckGroups] = useState<TruckGroup[]>([]);
	const [truckVariants, setTruckVariants] = useState<TruckVariant[]>([]);

	// dynamic
	const [districts, setDistricts] = useState<District[]>([]);
	const [neighbourhoods, setNeighbourhoods] = useState<Neighbourhood[]>([]);

	const UsageList = [  // Tarla:1 Bağ:2 Bahçe:4
		{ label: 'Tarla', value: 1 },
		{ label: 'Bahçe', value: 4 },
		{ label: 'Bağ-Bahçe', value: 6 },
		{ label: 'Tarla-Bahçe', value: 5 },
	];

	const validationSchema = Yup.object().shape({
		formId: Yup.string().nullable(),
		createDate: Yup.string().nullable(),
		salesRepUserId: Yup.string().nullable(),
		clientType: Yup.number().min(1, 'Müşteri türü seçiniz').required('Müşteri türü seçiniz'),
		taxNumber: Yup.string().length(11, 'TCKN/Vergi No 11 haneli olmalıdır').required('TCKN/Vergi No giriniz'),
		taxOffice: Yup.string().when('clientType', {
			is: (val) => [2, 3, 4, 5].includes(val),
			then: () => Yup.string().required('Vergi Dairesi giriniz'),
			otherwise: () => Yup.string().nullable(),
		}),
		fullName: Yup.string().required('Müşteri adı giriniz'),
		cityId: Yup.number().min(1, 'Şehir seçiniz').required('Şehir seçiniz'),
		districtId: Yup.number().min(1, 'İlçe seçiniz').required('İlçe seçiniz'),
		neighbourhoodId: Yup.number().min(1, 'Mahalle seçiniz').required('Mahalle seçiniz'),
		address: Yup.string().required('Adres giriniz'),
		phone: Yup.string().length(10).required('Telefon giriniz'),
		usageSelection: Yup.number().min(1, 'Kullanım alanı seçiniz').required('Kullanım alanı seçiniz'),
		requestedBrandId: Yup.number().min(1, 'Marka seçiniz').required('Marka seçiniz'),
		requestedGroupId: Yup.number().min(1, 'Grup seçiniz').required('Grup seçiniz'),
		requestedModelId: Yup.number().min(1, 'Model seçiniz').required('Model seçiniz'),
		requestedVariantId: Yup.number().min(1, 'Varyant seçiniz').required('Varyant seçiniz'),
		facebook: Yup.string().nullable(),
		instagram: Yup.string().nullable(),
		tiktok: Yup.string().nullable(),
		exchangeWith: Yup.string().nullable().default(null),
		farmAreaDeca: Yup.number().nullable().default(null),
		animalCount: Yup.number().nullable().default(null),
		note: Yup.string().required('Not giriniz'),
	});

	const formOptions = { resolver: yupResolver(validationSchema) };
	const { register, handleSubmit, formState, setValue, trigger, getValues } = useForm(formOptions);
	const { errors } = formState;

	const onSubmit = async (data) => {
		console.log('onsubmit', data);
		setSaving(true);
		var result = await formService.Update(accessToken!, data);
		console.log('result', result);
		rootState.fetchForms();
		setSaving(false);
		navigate('/forms');
		return false;
	};

	useEffect(() => {
		const loadForm = async (formId: string | undefined) => {
			if (formId) {
				console.log(`[form] loading form ${formId}`);
				setSaving(true);
				const _form = await formService.Load(accessToken, formId);
				if (!_form) {
					throw 'Form not found';
				}
				setValue('formId', formId);
				setValue('salesRepUserId', _form.salesRepUserId);
				const _formUser = users.find((u) => u.userId == _form.salesRepUserId);
				setFormUser(_formUser);
				setValue('clientType', _form.clientType);
				setValue('taxNumber', _form.taxNumber);
				setValue('taxOffice', _form.taxOffice);
				setValue('salesRepUserId', _form.salesRepUserId);
				setValue('note', _form.note ?? '');
				setValue('createDate', Tools.formatDate(_form.createDate));
				setValue('fullName', _form.fullName ?? '');
				setValue('address', _form.address ?? '');
				setValue('phone', _form.phone ?? '');
				setValue('facebook', _form.facebook ?? '');
				setValue('tiktok', _form.tiktok ?? '');
				setValue('instagram', _form.instagram ?? '');
				setValue('exchangeWith', _form.exchangeWith ?? '');
				setValue('animalCount', _form.animalCount ?? 0);
				setValue('farmAreaDeca', _form.farmAreaDeca ?? 0);

				setValue('cityId', _form.cityId);
				const _city = cities.find((c) => c.id == _form.cityId);
				if (_city) {
					setDistricts(_city.districts);
					setValue('districtId', _form.districtId);
					//trigger('districtId');
					const _district = _city.districts.find((c) => c.id == _form.districtId);
					if (_district) {
						setNeighbourhoods(_district.neighbourhoods);
						setValue('neighbourhoodId', _form.neighbourhoodId);
						//trigger('neighbourhoodId');
					}
				}

				const _truck = trucks.find((x) => x.id == _form.requestedModelId);
				if (_truck && _truck.type > 0 && getValues().usageSelection != _truck.type) {
					setValue('usageSelection', _truck?.type ?? 0);

					if (getValues().requestedBrandId != _truck.brandId) {
						setValue('requestedBrandId', _truck.brandId ?? 0);
						if (getValues().requestedGroupId != _truck.groupId) {
							setValue('requestedGroupId', _truck.groupId ?? 0);
							const myGroups = getGroups(_truck.brandId, _truck.type);
							setTruckGroups(myGroups);
							if (getValues().requestedModelId != _truck.id) {
								const myModels = getModels(_truck.brandId, _truck.groupId);
								setTruckModels([...myModels]);
								setValue('requestedModelId', _truck.id);
								const myVariants = variants.filter((x: TruckVariant) => x.groups.some(y => y.truckGroupID == _truck.groupId));
								setTruckVariants(myVariants);
								if (getValues().requestedVariantId != _form.requestedVariantId) {
									setValue('requestedVariantId', _form.requestedVariantId ?? 0);
									setMyTruckPrices(_truck.id, _form.requestedVariantId ?? 0);
								}
							}
						}
					}
				}
				setSaving(false);
			}
		};
		if (allLoaded && formId != undefined) {
			loadForm(formId);
		}
	}, [allLoaded, formId]);

	const validationClass = (show, s: string): string => show ? `${s}-invalid` : s;
	const goToMenu = () => setLocation('/forms');

	const clientTypeChanged = (e) => {
		const clientType = parseInt(e.target.value);
		//setMyForm({ ...myForm, clientType: clientType });
		setValue('clientType', clientType);
		console.log('clientType', clientType);
		trigger('clientType');
	};

	const cityChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const cityId = parseInt(e.target.value);
		const city = cities.find((c) => c.id == cityId);
		if (city) {
			setDistricts(city.districts);
			setValue('cityId', cityId);
		}
	};

	const districtChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const districtId = parseInt(e.target.value);
		const district = districts.find((c) => c.id == districtId);
		if (district) {
			setNeighbourhoods(district.neighbourhoods);
			setValue('districtId', districtId);
		}
	};

	const neighbourhoodChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const neighbourhoodId = parseInt(e.target.value);
		setValue('neighbourhoodId', neighbourhoodId);
	};

	const handlePhoneChange = (phone: string) => {
		setValue('phone', phone);
		trigger('phone');
	};

	const brandChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const brandId = parseInt(e.target.value);
		const brand = brands.find((c) => c.id == brandId);
		if (brand) {
			setTruckGroups(groups.filter((x) => x.brand == brandId));
			setValue('requestedBrandId', brandId);
			setValue('requestedGroupId', 0);
			setValue('requestedModelId', 0);
			setValue('requestedVariantId', 0);
			setPrices([]);
			trigger('requestedBrandId');
		}
	};

	const usageChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const usage = parseInt(e.target.value);
		console.log('[fey] usage (with clear)', usage);
		setValue('usageSelection', usage);
		setValue('requestedBrandId', 0);
		setValue('requestedGroupId', 0);
		setValue('requestedModelId', 0);
		setValue('requestedVariantId', 0);
		setPrices([]);
		trigger('usageSelection');
		console.log('[fey] usage (with clear ended)');
	};

	const getTruckCount = (brand: Brand, usage: number): number => {
		return trucks.filter((x) => x.brandId == brand.id && x.type == usage).length ?? 0;
	};

	const getGroups = (brandid: number, usage: number): TruckGroup[] => {
		var filteredTrucks = trucks.filter((x) => x.brandId == brandid && x.type == usage);
		var groupIds = filteredTrucks.map((x) => x.groupId);
		var filteredGroups = groups.filter((x) => groupIds.includes(x.id));
		return filteredGroups;
	};

	const getModels = (brandId: number, groupId: number) => {
		const uniqueModels = new Map<string, Truck>();
		trucks.forEach(truck => {
			if (truck.groupId === groupId && truck.brandId == brandId && !uniqueModels.has(truck.model.trim())) {
				console.log(`[fey] ${truck.model.trim()}`);
				uniqueModels.set(truck.model.trim(), truck);
			}
		});
		return uniqueModels.values();
	};

	const groupChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		console.log(`[fey] groupChanged`);
		const groupId = parseInt(e.target.value);
		setValue('requestedGroupId', groupId);

		const group = truckGroups.find((c) => c.id == groupId);
		if (group) { // i love you
			const uniqueModels = getModels(getValues().requestedBrandId, groupId);
			setTruckModels([...uniqueModels]);

			const variantList = variants.filter((x: TruckVariant) => x.groups.some(y => y.truckGroupID == groupId));
			setTruckVariants(variantList);
			setValue('requestedModelId', 0);
			setValue('requestedVariantId', 0);
			setPrices([]);
		}
		trigger('requestedGroupId');
	};

	const modelChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		console.log('modelChanged', e.target.value);
		const modelId = parseInt(e.target.value);
		setValue('requestedModelId', modelId);
		// if (getValues().requestedVariantId != 0) {
		// 	setMyTruckPrices(modelId, getValues().requestedVariantId);
		// } else {
		// 	setPrices([]);
		// }
		setValue('requestedVariantId', 0);
		setPrices([]);
		trigger('requestedModelId');
	};

	const setMyTruckPrices = (modelId: number, variantId: number) => {
		const modelName = trucks.filter((x) => x.id == modelId)[0].model.trimEnd();
		const myTruck = trucks.filter((x) => x.model.trimEnd() == modelName && x.truckVariantId == variantId);
		if (myTruck.length > 0) {
			console.log('myTruck', myTruck[0]);
			const _prices = [myTruck[0].priceWithoutVAT, myTruck[0].priceWithVAT];
			setPrices([..._prices]);
			console.log('combo prices', _prices);
		}
	};

	const variantChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const variantId = parseInt(e.target.value);
		console.log('variantChanged', variantId);
		setValue('requestedVariantId', variantId);
		const modelId = getValues().requestedModelId;
		console.log('modelId', modelId);
		console.log('variantId', variantId);
		setMyTruckPrices(modelId, variantId);
		trigger('requestedVariantId');
	};

	const showTruckType = (number: number): string => {
		const out: string[] = [];
		if (number & 1) {
			out.push('Tarla');
		}
		if (number & 2) {
			out.push('Bağ');
		}
		if (number & 4) {
			out.push('Bahçe');
		}
		return out.join(', ');
	};

	const clientPerson = [1, 6].includes(Number(getValues().clientType));
	const clientCompany = [2, 3, 4, 5].includes(Number(getValues().clientType));
	const brandSelected = getValues().requestedBrandId != undefined && getValues().requestedBrandId != 0;
	const groupSelected = getValues().requestedGroupId != undefined && getValues().requestedGroupId != 0;
	const modelSelected = getValues().requestedModelId != undefined && getValues().requestedModelId != 0;
	const formBranch = branches.find((b) => b.id == formUser?.branchId);

	return (allLoaded &&
		<form onSubmit={handleSubmit(onSubmit)}>
			<h1 className="mt-2 ml-4 text-2xl">Yeni Müşteri Görüşme Formu</h1>
			<div className="flex flex-row mx-8 my-2 mt-8 text-lg">
				<div className="form-left2">Satış Danışmanı</div>
				<div className="form-right">{formUser?.fullName}</div>
			</div>
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left2">Şube</div>
				<div className="form-right">{formBranch?.name}</div>
			</div>
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left2">Tarih</div>
				<div className="form-right">{getValues().createDate}</div>
			</div>
			{/* clientType */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left3">
					Müşteri Tipi
				</div>
				<div className="form-right-no-border ">
					<div className={validationClass((errors.clientType?.message), 'section')}>
						{
							clientTypes.map((ct) => (
								<label key={ct.id} className='px-1 mx-1'>
									<input type="radio" key={`CID_${ct.id}`} value={ct.id} {...register('clientType', { onChange: (e) => clientTypeChanged(e) })} checked={getValues().clientType === ct.id} /> {ct.name}
								</label>
							))
						}
					</div>
					<div className='error'>{errors.clientType?.message}</div>
				</div>
			</div>
			{/* fullName */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left3">
					Adı Soyadı <sup>*</sup>
				</div>
				<div className="form-right-no-border ">
					<div className={validationClass((errors.fullName?.message), 'section')}>
						<input type="text" {...register('fullName')} className='onelineinputx' />
						<div className='error'>{errors.fullName?.message}</div>
					</div>
				</div>
			</div>
			{/* tax number */}
			{getValues().clientType > 0 && (
				<div className="flex flex-row mx-8 my-4 text-lg">
					<div className="form-left3">
						{clientPerson && <span>TCKN</span>}
						{clientCompany && <span>Vergi No</span>}
						<sup>*</sup>
					</div>
					<div className="form-right-no-border ">
						<div className={validationClass((errors.taxNumber), 'section')}>
							<input maxLength={11} {...register('taxNumber')} />
							<div className='error'>{errors.taxNumber?.message}</div>
						</div>
					</div>
				</div>
			)}
			{/* tax office */}
			{[2, 3, 4, 5].includes(getValues().clientType) && (
				<div className="flex flex-row mx-8 my-4 text-lg">
					<div className="form-left3">
						<span>Vergi Dairesi</span><sup>*</sup>
					</div>
					<div className="form-right-no-border ">
						<div className={validationClass((errors.taxOffice), 'section')}>
							<input {...register('taxOffice')} maxLength={50} />
							<div className='error'>{errors.taxOffice?.message}</div>
						</div>
					</div>
				</div>
			)}

			{/* city/district */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left3">
					Şehir <sup>*</sup>
				</div>
				<div className="form-right-no-border ">
					<select {...register('cityId', { onChange: (e) => cityChanged(e) })} className={validationClass((errors.cityId), 'select')} >
						<option value="0">???</option>
						{cities.map((city) => (
							<option key={`city_${city.id}`} value={city.id}>
								{city.name}
							</option>
						))}
					</select>
					<div className='error'>{errors.cityId?.message}</div>
				</div>
				<div className="form-left3">
					İlçe <sup>*</sup>
				</div>
				<div className="form-right-no-border">
					<select {...register('districtId', { onChange: (e) => districtChanged(e) })}
						value={getValues().districtId}
						className={validationClass((errors.districtId), 'select')} >
						<option value="0">???</option>
						{districts.map((dist) => (
							<option key={`dist_${dist.id}`} value={dist.id}>
								{dist.name}
							</option>
						))}
					</select>
					<div className='error'>{errors.districtId?.message}</div>
				</div>
			</div>
			{/* neighbourhood */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left3">
					Mahalle <sup>*</sup>
				</div>
				<div className="form-right-no-border ">
					<select  {...register('neighbourhoodId', { onChange: (e) => neighbourhoodChanged(e) })}
						value={getValues().neighbourhoodId}
						className={validationClass((errors.neighbourhoodId), 'select')}>
						<option value="0">???</option>
						{neighbourhoods.map((n) => (
							<option key={`neighbourhood_${n.id}`} value={n.id}>
								{n.name}
							</option>
						))}
					</select>
					<div className='error'>{errors.neighbourhoodId?.message}</div>
				</div>
			</div>
			{/* adres */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left3">
					Adres <sup>*</sup>
				</div>
				<div className="form-right-no-border ">
					<textarea {...register('address')} className={validationClass((errors.address), 'textarea')} />
					<div className='error'>{errors.address?.message}</div>
				</div>
			</div>
			{/* tel */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left3">
					Tel <sup>*</sup>
				</div>
				<div className="form-right-no-border">
					<div className={validationClass((errors.phone), 'onelineinputx')} >
						<PhoneInput name='phone' value={getValues().phone} onPhoneChange={(p) => handlePhoneChange(p)} />
						<div className='error'>{errors.phone?.message}</div>
					</div>
				</div>
			</div>
			{/* social media */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left4">Facebook</div>
				<div className="form-right-no-border">
					<input type="text" {...register('facebook')} className="onelineinputx" />
				</div>
			</div>
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left4">Instagram</div>
				<div className="form-right-no-border">
					<input type="text" {...register('instagram')} className="onelineinputx" />
				</div>
			</div>
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left4">Tiktok</div>
				<div className="form-right-no-border">
					<input type="text" {...register('tiktok')} className="onelineinputx" />
				</div>
			</div>
			{/* kullanim */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left">Kullanım</div>
				<div className="form-right-no-border ">
					<select {...register('usageSelection', { onChange: (e) => usageChanged(e) })}
						className={validationClass((errors.usageSelection), 'onelineinputx')}>
						<option value="0">???</option>
						{UsageList.map((n) => (
							<option key={n.value} value={n.value}>
								{n.label}
							</option>
						))}
					</select>
					<div className='error'>{errors.usageSelection?.message}</div>
				</div>
			</div>
			{/* brand */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left">
					Talep edilen marka <sup>*</sup>
				</div>
				<div className="form-right-no-border">
					<select {...register('requestedBrandId', { onChange: (e) => brandChanged(e) })}
						value={getValues().requestedBrandId}
						className={validationClass((errors.requestedBrandId), 'select')}>
						<option key="BRAND_0" value="0">Seçiniz.</option>
						{brands.map((brand) => {
							const truckCount = getTruckCount(brand, getValues().usageSelection);
							if (truckCount == 0) {
								return null;
							}
							return (<option key={brand.id} value={brand.id}>{brand.name} [{truckCount}]</option>);
						})}
					</select>
					<div className='error'>{errors.requestedBrandId?.message}</div>
				</div>
			</div>
			{/* truckGroup */}
			{brandSelected && (
				<div className="flex flex-row mx-8 my-4 text-lg">
					<div className="form-left">Grup <sup>*</sup></div>
					<div className="form-right-no-border">
						<select {...register('requestedGroupId', { onChange: (e) => groupChanged(e) })}
							value={getValues().requestedGroupId}
							className={validationClass((errors.requestedGroupId), 'select')}>
							<option key="GROUP_0" value="0">Grup seçiniz.</option>
							{getGroups(getValues().requestedBrandId, getValues().usageSelection).map((group) => (
								<option key={`GROUP_${group.id}`} value={group.id}>
									{group.name}
								</option>
							))}
						</select>
						<div className='error'>{errors.requestedGroupId?.message}</div>
					</div>
				</div>
			)}
			{/* model */}
			{groupSelected && brandSelected && (
				<div className="flex flex-row mx-8 my-4 text-lg">
					<div className="form-left">
						Talep edilen model <sup>*</sup>
					</div>
					<div className="form-right-no-border">
						<select {...register('requestedModelId', { onChange: (e) => modelChanged(e) })}
							value={getValues().requestedModelId}
							className={validationClass((errors.requestedModelId), 'select')}>
							<option key="BRAND_0" value="0">
								Model seçiniz.
							</option>
							{truckModels.map((truck) => (
								<option key={`TRUCK_${truck.id}`} value={truck.id}>
									{truck.model} ({showTruckType(truck.type)})
								</option>
							))}
						</select>
						<div className='error'>{errors.requestedModelId?.message}</div>
					</div>
				</div>
			)}
			{/* variant */}
			{groupSelected && brandSelected && modelSelected && (
				<div className="flex flex-row mx-8 my-4 text-lg">
					<div className="form-left">
						Varyant <sup>*</sup>
					</div>
					<div className="form-right-no-border">
						<select {...register('requestedVariantId', { onChange: (e) => variantChanged(e) })}
							value={getValues().requestedVariantId}
							className={validationClass((errors.requestedVariantId), 'select')}>
							<option key="VAR_0" value="0">
								Varyant seçiniz.
							</option>
							{truckVariants.map((variant) => (
								<option key={variant.id} value={variant.id}>
									{variant.name}
								</option>
							))}
						</select>
					</div>
					<PriceBoxComponent prices={prices} />
				</div>
			)}
			{/* exchangeWith */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left">Takas traktörü</div>
				<div className="form-right-no-border">
					<input type="text" {...register('exchangeWith')} className="onelineinputx" />
				</div>
			</div>
			{/* Arazi */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left">Arazi (dekar)</div>
				<div className="form-right-no-border">
					<div className={validationClass((errors.farmAreaDeca), 'onelineinputx')}>
						<input type="number" {...register('farmAreaDeca')} />
						<div className='error'>{errors.farmAreaDeca?.message}</div>
					</div>
				</div>
			</div>
			{/* Hayvan */}
			<div className="flex flex-row mx-8 my-4 text-lg">
				<div className="form-left">Hayvan sayısı (adet)</div>
				<div className="form-right-no-border">
					<div className={validationClass((errors.animalCount), 'onelineinputx')}>
						<input type="number" {...register('animalCount')} />
						<div className='error'>{errors.animalCount?.message}</div>
					</div>
				</div>
			</div>
			{/* Notlar */}
			<div className="flex flex-row mx-8 text-lg">
				<div className="form-left">Notlar</div>
				<div className="flex-1 form-right-no-border">
					<div className={validationClass((errors.note), 'notes')}>
						<textarea className='textarea' {...register('note')} />
						<div className='error'>{errors.note?.message}</div>
					</div>
				</div>
			</div>


			<div className="flex flex-row mx-8 my-4 text-xl select-none">
				<div className="w-full text-right">
					<Button color="green" className="float-right mx-2" type="submit">
						{saving && <span className="absolute inline-flex w-8 h-8 ml-6 rounded-full opacity-75 animate-ping bg-sky-400"></span>}
						Kaydet
					</Button>
					<Button className='float-right' color="red" type='button' onClick={goToMenu}>Kapat</Button>
				</div>
			</div>
		</form >
	);
};


export default FormEditInlet;