import React from 'react';
import { Slide } from 'react-slideshow-image';
import { LoginComponent } from '../components/loginComponent';
import { VersionComponent } from '../components/versionComponent';
import dot from '../assets/dot.png';
import images from '../components/images';

const MainPage: React.FC = () => {
	const divStyle = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundSize: 'cover',
		height: '100vh',
		zIndex: '-200',
		backgroundPosition: 'center',
	};

	return (
		<>
			<div className="sliderBG">
				<Slide arrows={false} infinite={true} autoplay={true} duration={4000} transitionDuration={500}>
					{images.images.map((slideImage, index) => (
						<div key={index}>
							<div
								style={{
									...divStyle,
									backgroundImage: `url(${slideImage})`,
								}}></div>
						</div>
					))}
				</Slide>
			</div>
			<div className="dottedLayer" style={{ backgroundImage: `url(${dot})` }}>
				<VersionComponent />
				<LoginComponent />
			</div>
		</>
	);
};

export default MainPage;
