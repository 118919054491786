import React, { useEffect } from 'react';
import { useLocation } from 'wouter';

const HomePageInlet: React.FC = () => {
	// eslint-disable-next-line no-unused-vars
	const [location, navigate] = useLocation();
	//const [openModal, setOpenModal] = useState(false);
	useEffect(() => {
		navigate('/forms');
	}, []);
	return (
		<div className="flex card justify-content-center">

		</div>
	);
};



export default HomePageInlet;