import React, { useEffect } from 'react';
import { useLocation } from 'wouter';
import { useDanteStore } from '../store/store';

const Logout: React.FC = () => {

	const [, setLocation] = useLocation();
	const logout = useDanteStore(state => state.logout);

	useEffect(() => {
		logout();
		setLocation('/');
	});
	return (
		<>
			...
		</>
	);
};

export default Logout;
